// import React from "react";
// import CreateBookingSelectBox from "../../CreateBookingNewPage/CreateBookingSelectBox/CreateBookingSelectBox";
// import DateRangePickerNew from "../../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
// import "../../CreateBookingNewPage/CreateBookingNewPage.css";
// import CreateBookingSelectBoxOptionDisabled from "../../CreateBookingNewPage/createBookingSelectBoxOptionDisabled/CreateBookingSelectBoxOptionDisabled";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";

// import AgentHoldRoomsCreateBookingCard from "./AgentHoldRoomsCreateBookingCard";
// import CreateBookingInputBox from "../../CreateBookingNewPage/CreateBookingInputBox/CreateBookingInputBox";
// import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
// import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
// import {
//   formatDateYYYYMMDD,
//   formatIndianCurrency,
//   getCurrentPropertyDateHandler,
//   useCurrentTime,
// } from "../../../utils/utils";

// const AgentHoldRoomsCreateBooking = () => {
//   const { ID } = useParams();
//   const userToken = JSON.parse(localStorage.getItem("userToken"));
//   const property_id = JSON.parse(localStorage.getItem("selectedProperty"));
//   // const property_id = useSelector((state) => state.property.value);
//   const navigate = useNavigate();

//   const currentTimeToBackend = useCurrentTime("24");

//   //..date range ....
//   const currentDate = new Date().toISOString().split("T")[0];

//   const [checkIn, setCheckIn] = useState("");
//   const [checkOut, setCheckOut] = useState("");

//   const dataFromChild = (from_date, to_date) => {
//     // setErrors((prev) => ({ ...prev, no_of_nights: false }));

//     setCheckIn(from_date);
//     setCheckOut(to_date);
//     setBookingData((prevData) => ({
//       ...prevData,
//       check_in: from_date,
//       check_out: to_date,
//     }));
//   };

//   const BookingTypeOptions = [
//     { room_name: "Single", value: "single" },
//     { room_name: "Multiple", value: "multiple" },
//   ];
//   // calculate the number of nights from check in check out

//   const [bookingData, setBookingData] = useState({
//     booking_type: "",
//     available_rooms: "",
//     extra_adult_allowed: "",
//     check_out: null,
//     check_in: null,
//     no_of_nights: "",
//     no_of_adults: "",
//     extra_adults: "",
//     no_of_child: "",
//     no_of_rooms: "",
//     room_category: "",
//     room_id: "",
//     booking_source: "",
//     agent_id: JSON.parse(localStorage.getItem("agentDetails"))?.details?.agent_information,
//     discount: "",
//     tax_type: "inclusive",
//     tac_discount: "",
//     selected_discount: "",
//     room_plans: "",
//     ota_source: "",
//     agent_name: "",
//   });

//   console.log(bookingData ,"booking data")
//   useEffect(() => {
//     const calculateNumberOfNights = () => {
//       const checkIn = new Date(bookingData.check_in);
//       const checkOut = new Date(bookingData.check_out);
//       const timeDifference = checkOut.getTime() - checkIn.getTime();
//       const numberOfNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
//       return numberOfNights;
//     };

//     const numberOfNightsForBooking = calculateNumberOfNights();

//     setBookingData((prevData) => ({
//       ...prevData,
//       no_of_nights: numberOfNightsForBooking,
//     }));
//   }, [bookingData.check_in, bookingData.check_out]);

//   //handle booking change
//   const handleBookingTypeChange = (event) => {
//     setErrors((prev) => ({ ...prev, booking_type: false }));
//     setBookingData({
//       ...bookingData,
//       booking_type: event.target.value,
//     });
//   };

//   //   ................Room Category Amd fetching room plans.....................

//   const [RoomCategoryOptions, setRoomCategoryOptions] = useState("");

//   const fetchRoomTypeLists = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/agent/hold/inventory/details/${ID}/${property_id?.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json();
//       if (data.message === "Unauthorised.") {
//         navigate("/");
//       }
//       setRoomCategoryOptions(data?.data.roomDetails);
//       setBookingData((prev) => ({
//         ...prev,
//         hold_from_date: data.data.from_date,
//         hold_to_date: data.data.to_date,
//       }));
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (property_id?.id) {
//       fetchRoomTypeLists();
//     }
//   }, [property_id?.id]);

//   const handleRoomCategory = async (e) => {
//     if (e.target.value === "") {
//       setBookingData((perv) => ({
//         ...perv,
//         room_category: "",
//         limits: "",
//       }));
//       setAvailableRooms("");

//       return;
//     }
//     setBookingData((prev) => ({
//       ...prev,
//       no_of_rooms: "",
//       no_of_adults: "",
//       no_of_child: "",
//       extra_adults: "",
//     }));

//     setErrors((prev) => ({ ...prev, room_category: false }));
//     const id = RoomCategoryOptions.find(
//       (item) => item?.room_name === e.target.value
//     )?.room_id;
//     const roomCategory = e.target.value;
//     // api  for fetching the room plans

//     const checkIn = bookingData.check_in;
//     const checkOut = bookingData.check_out;
//     // check availability api
//     // https://dev.revcatalyst.in/api/v1/check/hold/rooms/availability/1?id=19&check_in=2024-07-31&check_out=2024-08-01&room_id=2
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/agent-dashboard/check/hold/rooms/availability/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}&room_id=${id}&id=${ID}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }

//       const data = await response.json();

//       const blockedRooms = data?.available_rooms_dates?.filter(
//         (item) => item?.blocked !== 0
//       );
//       // if (blockedRooms?.length > 0) {
//       //   showErrorToast(
//       //     `Room is blocked for selected dates :  ${blockedRooms
//       //       .map((room) => room.date)
//       //       .join(", ")}      `
//       //   );
//       //   return;
//       // } else {
//       setAvailableRooms(data);
//       setBookingData((prev) => ({
//         ...prev,
//         limits: data,
//       }));

//       //  fetch room plans
//       fetchRoomRates(id, roomCategory);
//       // }

//       if (data.message === "Unauthorised.") {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const fetchRoomRates = async (id, roomCategory) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/agent-dashboard/get/rooms/rates/${id}/${property_id?.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       if (!response.ok) {
//         throw new Error(
//           `Failed to fetch data: ${response.status} ${response.statusText}`
//         );
//       }
//       const data = await response.json();

//       if (data.success === true) {
//         setBookingData((prev) => ({
//           ...prev,
//           room_plans: data?.roomRates,
//           room_category: roomCategory,
//           room_id: id,
//         }));
//       }
//     } catch (error) {
//       console.error("Error fetching room plans:", error);
//       showErrorToast("something went wrong please try again...");
//     }
//   };

//   const [availableRooms, setAvailableRooms] = useState("");

//   const handleInputChange = (e) => {
//     const extraBeddingAllowed =
//       availableRooms?.extra_bedding_allowed == "enabled" ? true : false;

//     const { name, value } = e.target;

//     if (name === "agent_id") {
//       const findAgent = agentList.find(
//         (item) => item.agent_information == value
//       );

//       setBookingData({
//         ...bookingData,
//         [name]: value,
//         booking_source_name: findAgent?.agent_name,
//       });
//     } else {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//         tac_discount: 0,
//       });
//     }
//   };
//   const handleInputOtaChange = (e) => {
//     setErrors((prev) => ({ ...prev, ota_source: false }));
//     const { name, value } = e.target;
//     setBookingData({
//       ...bookingData,
//       [name]: value,
//     });
//   };

//   const handleInputSelectAgentChange = (e) => {
//     setErrors((prev) => ({ ...prev, agent_id: false }));
//     const { name, value } = e.target;

//     // if

//     const selectedAgent = agentList.find(
//       (item) => +item.agent_information === +value
//     );
//     const agentName = selectedAgent ? selectedAgent.company_name : "";

//     setBookingData({
//       ...bookingData,
//       [name]: value,
//       booking_source_name: agentName,
//     });
//   };

//   const handleInputAgentNameChange = (e) => {
//     setErrors((prev) => ({ ...prev, agent_name: false }));
//     const { name, value } = e.target;
//     setBookingData({
//       ...bookingData,
//       [name]: value,
//     });
//   };

//   // booking submit handler
//   const BookingSourceOptions = [
//     { room_name: "Walk In Booking", value: "walk in booking" },
//     { room_name: "Front Desk", value: "front desk" },
//     { room_name: "Sales and Marketing", value: "sales and marketing" },
//     { room_name: "Agent (B2B) Partner", value: "agent" },
//     { room_name: "OTA", value: "ota" },
//   ];

//   const handleBookingSourceChange = (e) => {
//     setErrors((prev) => ({
//       ...prev,
//       booking_source: false,
//       agent_id: false,
//       agent_name: false,
//     }));
//     setBookingData({
//       ...bookingData,
//       booking_source: e.target.value,
//       tac_discount: 0,
//       agent_id: "",
//       agent_name: "",
//     });
//   };

//   //booking agent

//   const [agentList, setAgentList] = useState([]);

//   const fetchAgentList = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }

//       const data = await response.json();
//       setAgentList(data.data);

//       if (data.message === "Unauthorised.") {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (
//       bookingData.booking_source === "Agent (B2B) Partner" &&
//       property_id?.id
//     ) {
//       fetchAgentList();
//     }
//   }, [bookingData.booking_source, property_id?.id]);

//   // Tac discount
//   const fetchTacDiscount = async () => {
//     const agentId = bookingData.agent_id;

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/agents/tac/discount/${agentId}/${property_id?.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }

//       const data = await response.json();
//       setBookingData({
//         ...bookingData,
//         tac_discount: data.tac_discount,
//       });
//       if (data.message === "Unauthorised.") {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (bookingData.agent_id && property_id?.id) {
//       fetchTacDiscount();
//     }
//   }, [bookingData.agent_id, property_id?.id]);

//   // for discount percentage
//   const [discountOptions, setDiscountOptions] = useState([]);
//   const fetchDiscountOptions = async () => {
//     const checkIn = bookingData.check_in;
//     const checkOut = bookingData.check_out;
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/discount/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }

//       const data = await response.json();
//       setDiscountOptions(data.discount);

//       if (data.message === "Unauthorised.") {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error("Error fetching discount options:", error);
//     }
//   };
//   useEffect(() => {
//     if (bookingData.check_in && bookingData.check_out && property_id?.id) {
//       fetchDiscountOptions();
//     }
//   }, [
//     bookingData.check_in,
//     bookingData.check_out,
//     property_id?.id,
//     checkIn,
//     checkOut,
//   ]);

//   // handle click on Add button save booking data in Booking array
//   const [bookingDataArray, setBookingDataArray] = useState([]);

//   const [showDiscountSelect, setShowDiscountSelect] = useState(true);
//   const handleDeleteBookingCard = (index) => {
//     setBookingDataArray((prev) => prev.filter((_, i) => i !== index));
//     setAdhocArray((prev) => prev.filter((_, i) => i !== index));
//   };

//   // ota sources
//   const otaTypeOptions = [
//     { label: "MakeMyTrip", value: "makemytrip" },
//     { label: "GoIbibo", value: "goibibo" },
//     { label: "Expedia", value: "expedia" },
//     { label: "EaseMyTrip", value: "easemytrip" },
//     { label: "ClearTrip", value: "cleartrip" },
//     { label: "HappyEasyGo", value: "happyeasygo" },
//     { label: "Travelguru", value: "travelguru" },
//     { label: "Yatra", value: "yatra" },
//     { label: "Agoda", value: "agoda" },
//     { label: "Booking.com", value: "booking.com" },
//   ];

//   const [errors, setErrors] = useState({
//     booking_type: false,
//     no_of_nights: false,
//     no_of_rooms: false,
//     room_category: false,
//     booking_source: false,
//     agent_id: false,
//     no_of_adults: false,
//     discount_type: false,
//     agent_id: false,
//     agent_name: false,
//     ota_source: false,
//     agent_name: false,
//     extra_adult: false,
//     no_of_child: false,
//     guest_name: false,
//   });

//   const handleClickOnAddButton = () => {
//     if (isDisabled()) {
//       return;
//     }
//     // const blockedRooms = availableRooms?.available_rooms_dates?.filter(
//     //   (item) => item?.blocked !== 0
//     // );
//     // if (blockedRooms?.length > 0) {
//     //   showErrorToast(
//     //     `Room is blocked for selected dates :  ${blockedRooms
//     //       .map((room) => room.date)
//     //       .join(", ")}      `
//     //   );
//     //   return;
//     // }

//     const hasSingleBooking = bookingDataArray.some(
//       (item) => item.booking_type === "Single"
//     );

//     const hasSameRoomCategory = bookingDataArray.some(
//       (item) => item.room_category === bookingData.room_category
//     );
//     if (hasSameRoomCategory) {
//       showErrorToast("Same room category cannot be added twice");
//       return;
//     }

//     if (hasSingleBooking) {
//       showErrorToast("Only one booking can be created at a time");
//     } else {
//       setBookingDataArray((prevData) => [...prevData, bookingData]);
//       setAvailableRooms({});
//       setTimeout(() => {
//         setBookingData((prevData) => ({
//           ...prevData,
//           no_of_rooms: "",
//           no_of_adults: "",
//           extra_adults: "",
//           extra_adult: "",
//           no_of_child: "",
//           room_category: "",
//         }));
//       }, 500);
//       // setDiscountOptions([]);
//     }
//   };

//   const isDisabled = () => {
//     setTimeout(() => {
//       setErrors((prev) => ({
//         ...prev,
//         booking_type: false,
//         no_of_nights: false,
//         no_of_rooms: false,
//         room_category: false,
//         booking_source: false,
//         agent_id: false,
//         no_of_adults: false,
//         discount_type: false,
//         agent_name: false,
//         ota_source: false,
//         extra_adult: false,
//         no_of_child: false,
//         guest_name: false,
//       }));
//     }, 5000);

//     if (
//       bookingData.booking_type === "" ||
//       bookingData.no_of_nights === "" ||
//       bookingData.no_of_rooms === "" ||
//       bookingData.room_category === "" ||
//       bookingData.booking_source === ""
//     ) {
//       showErrorToast("Please fill in all required fields.");
//       if (bookingData.booking_type === "") {
//         setErrors((prev) => ({ ...prev, booking_type: true }));
//       }
//       if (bookingData.no_of_nights === 0) {
//         setErrors((prev) => ({ ...prev, no_of_nights: true }));
//       }
//       if (bookingData.room_category === "") {
//         setErrors((prev) => ({ ...prev, room_category: true }));
//       }
//       if (bookingData.booking_source === "") {
//         setErrors((prev) => ({ ...prev, booking_source: true }));
//       }
//       if (bookingData.no_of_rooms === "") {
//         setErrors((prev) => ({ ...prev, no_of_rooms: true }));
//       }
//       if (bookingData.no_of_adults === "") {
//         setErrors((prev) => ({ ...prev, no_of_adults: true }));
//       }
//       return true;
//     }

//     if (
//       bookingData.booking_source === "Agent (B2B) Partner" &&
//       bookingData.agent_id === ""
//     ) {
//       setErrors((prev) => ({ ...prev, agent_id: true }));
//       showErrorToast("Please Select Agent for B2B bookings.");
//       return true;
//     }

//     if (bookingData.booking_source === "OTA" && bookingData.ota_source === "") {
//       setErrors((prev) => ({ ...prev, ota_source: true }));
//       showErrorToast("Please provide the OTA Source for OTA bookings.");
//       return true;
//     }
//     if (
//       bookingData.booking_source === "Sales and Marketing" &&
//       bookingData.agent_name === ""
//     ) {
//       setErrors((prev) => ({ ...prev, agent_name: true }));
//       showErrorToast(
//         "Please provide the Agent Name for Sales and Marketing bookings."
//       );
//       return true;
//     }

//     return false;
//   };

//   function calculateTotal(array, property) {
//     // Check if the input is an array
//     if (!Array.isArray(array)) {
//       return 0;
//     }

//     return array.reduce((total, item) => {
//       const value = parseFloat(item[property]) || 0; // Convert to number, default to 0 if NaN
//       return total + value;
//     }, 0);
//   }

//   const [guestDetails, setGuestDetails] = useState({
//     salutation: "",
//     name: "",
//     email: "",
//     phone: "",
//     remarks: "",
//   });

//   // calculating the total of the booking arrays and saving them

//   const sumSubTotal = calculateTotal(bookingDataArray, "subTotal") || 0;
//   const sumGrandTotal = calculateTotal(bookingDataArray, "grandTotal");
//   const sumDiscountAmount =
//     calculateTotal(bookingDataArray, "discountAmount") || 0;

//   const sumTaxAmount = calculateTotal(bookingDataArray, "taxAmount") || 0;

//   // handle confirm booking button

//   const isConfirmDisabled = () => {
//     if (
//       guestDetails.salutation === "" ||
//       guestDetails.name === ""
//       // guestDetails.email === "" ||
//       // guestDetails.phone === ""
//     ) {
//       setErrors((prev) => ({ ...prev, guest_name: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, guest_name: false }));
//       }, 5000);
//       showErrorToast("Please fill in all required fields.");
//       return true;
//     }
//   };

//   // on click of hold button show the hold modal

//   const [showHoldPopup, setShowHoldPopup] = useState(false);

//   const [holdBookingData, setHoldBookingData] = useState({
//     hold_date: "",
//     hold_time: "",
//     hold_reason: "",
//   });

//   // function wrote  for showing the date format according to indian standard
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     if (isNaN(date.getTime())) {
//       return "dd/mm/yyyy";
//     } else {
//       const day = String(date.getDate()).padStart(2, "0");
//       const month = String(date.getMonth() + 1).padStart(2, "0");
//       const year = date.getFullYear();
//       return `${day}/${month}/${year}`;
//     }
//   };

//   const [addOnsPaymentData, setAddOnsPaymentData] = useState({
//     addOn: [],
//     payments: [],
//   });

//   const sumPaidAmount =
//     calculateTotal(addOnsPaymentData.payments, "amount") || 0;

//   // add ons cost

//   const sumSubtotalAddons =
//     calculateTotal(addOnsPaymentData.addOn, "price") || 0;

//   const sumGrandTotalAddons =
//     calculateTotal(addOnsPaymentData.addOn, "total") || 0;

//   const sumTaxAmountAddons =
//     calculateTotal(addOnsPaymentData.addOn, "tax_amount") || 0;

//   // new handler for room change
//   const handleNumberOfRoomsChange = (e) => {
//     const { name, value } = e.target;

//     // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
//     if (value === "" || isNaN(value)) {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//         no_of_adults: "",
//         no_of_child: "",
//         extra_adults: "",
//       });
//       setErrors((prev) => ({ ...prev, no_of_rooms: false }));
//       return;
//     }

//     const selectedRooms = +value;
//     const prevAvailableRooms = availableRooms?.available_rooms;

//     if (selectedRooms <= 0) {
//       showErrorToast("Please enter a valid number of rooms");
//       setErrors((prev) => ({ ...prev, no_of_rooms: true }));
//       return;
//     }

//     if (selectedRooms > prevAvailableRooms) {
//       showErrorToast(
//         "Entered number cannot be greater than the number of available rooms"
//       );
//       setErrors((prev) => ({ ...prev, no_of_rooms: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, no_of_rooms: false }));
//       }, 5000);
//       return;
//     }

//     // Update the booking data with the new value
//     setBookingData({
//       ...bookingData,
//       [name]: value,
//       no_of_adults: "",
//       no_of_child: "",
//       extra_adults: "",
//     });
//     setErrors((prev) => ({ ...prev, no_of_rooms: false }));
//   };

//   // handle number of adult
//   const handleNumberOfAdultChange = (e) => {
//     const { name, value } = e.target;

//     // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
//     if (value === "" || isNaN(value)) {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//       });
//       setErrors((prev) => ({ ...prev, no_of_adults: false }));
//       return;
//     }
//     if (isNaN(value) || +value <= 0) {
//       showErrorToast("Please enter a valid number of adults");
//       setErrors((prev) => ({ ...prev, no_of_adults: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, no_of_adults: false }));
//       }, 5000);

//       return;
//     }

//     if (+value > availableRooms?.base_adult * bookingData.no_of_rooms) {
//       showErrorToast(
//         "The number of adults cannot be greater than the base occupancy of a room"
//       );
//       setErrors((prev) => ({ ...prev, no_of_adults: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, no_of_adults: false }));
//       }, 5000);
//       return;
//     } else {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//       });
//       setErrors((prev) => ({ ...prev, no_of_adults: false }));
//     }
//     setTimeout(() => {
//       setErrors((prev) => ({ ...prev, no_of_adults: false }));
//     }, 5000);
//   };

//   //handle number of extra adult change
//   const handleNumberOfExtraAdultChange = (e) => {
//     const { name, value } = e.target;

//     // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
//     if (value === "" || isNaN(value)) {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//       });
//       setErrors((prev) => ({ ...prev, extra_adult: false }));
//       return;
//     }
//     if (isNaN(value) || +value <= 0) {
//       showErrorToast("Please enter a valid number of adults");
//       setErrors((prev) => ({ ...prev, extra_adult: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, extra_adult: false }));
//       }, 5000);
//       return;
//     }

//     if (availableRooms.extra_bedding !== "enabled") {
//       showErrorToast("Extra Bedding not allowed for this room");
//       setErrors((prev) => ({ ...prev, extra_adult: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, extra_adult: false }));
//       }, 5000);

//       setBookingData({
//         ...bookingData,
//         [name]: 0,
//       });
//       return; // Return early if extra bedding is not allowed
//     }
//     if (bookingData.no_of_child !== "") {
//       const backendMaxOccupancy =
//         availableRooms?.max_adult * +bookingData.no_of_rooms;
//       const frontEndMaxOccupancy =
//         +bookingData.no_of_adults + +bookingData.no_of_child;
//       const maxExtraAdultsAllowed =
//         (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
//         bookingData.no_of_rooms;
//       if (+value > +maxExtraAdultsAllowed) {
//         showErrorToast(
//           "Max occupancy reached! please increase number of rooms"
//         );
//         setBookingData({
//           ...bookingData,
//           [name]: "", // Clear the input value
//         });
//         return;
//       }
//     }

//     if (+value > availableRooms?.allowedExtraAdult * bookingData.no_of_rooms) {
//       showErrorToast("Value cannot exceed the available extra adult per room");
//       setErrors((prev) => ({ ...prev, extra_adult: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, extra_adult: false }));
//       }, 5000);
//       setBookingData({
//         ...bookingData,
//         [name]: "", // Clear the input value
//       });
//     } else {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//       });
//       setErrors((prev) => ({ ...prev, extra_adult: false }));
//     }
//   };

//   //handle number of child change

//   const handleNumberOfChildChange = (e) => {
//     const { name, value } = e.target;
//     if (value === "" || isNaN(value)) {
//       setBookingData({
//         ...bookingData,
//         [name]: value,
//       });
//       setErrors((prev) => ({ ...prev, no_of_child: false }));
//       return;
//     }
//     if (isNaN(value) || +value < 0) {
//       showErrorToast("Please enter a valid number of children");
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, no_of_child: false }));
//       }, 5000);
//       setErrors((prev) => ({ ...prev, no_of_child: true }));
//       return;
//     }

//     const childAllowed = availableRooms?.max_child;

//     if (+value > childAllowed * +bookingData.no_of_rooms) {
//       showErrorToast("Value cannot exceed the available child limit");
//       setErrors((prev) => ({ ...prev, no_of_child: true }));
//       setTimeout(() => {
//         setErrors((prev) => ({ ...prev, no_of_child: false }));
//       }, 5000);
//       setBookingData({
//         ...bookingData,
//         [name]: "", // Clear the input value
//       });
//       return;
//     }

//     const backendMaxOccupancy =
//       availableRooms?.max_adult * +bookingData.no_of_rooms;
//     const frontEndMaxOccupancy =
//       +bookingData.no_of_adults + +bookingData.extra_adults;
//     const maxChildAllowed =
//       (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
//       bookingData.no_of_rooms;
//     if (+value > +maxChildAllowed) {
//       showErrorToast("Max occupancy reached! please increase number of rooms");
//       setBookingData({
//         ...bookingData,
//         [name]: "", // Clear the input value
//       });
//       return;
//     }

//     setBookingData({
//       ...bookingData,
//       [name]: value,
//     });
//     setErrors((prev) => ({ ...prev, no_of_child: false }));
//   };

//   const handleGuestDetails = (e) => {
//     setGuestDetails({
//       ...guestDetails,
//       [e.target.name]: e.target.value,
//     });
//   };

//   // salutations

//   const [showSalutationPopup, setShowSalutationPopup] = useState(false);

//   const [salutations, setSalutations] = useState([]);

//   const fetchSalutations = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json();
//       if (response.ok) {
//         setSalutations(data.data);
//       } else if (data.message === "Unauthorised.") {
//         navigate("/");
//       } else {
//         console.error("Failed to fetch salutations:", data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching salutations:", error);
//     }
//   };

//   useEffect(() => {
//     fetchSalutations();
//   }, []);

//   const handleSalutationChange = (e) => {
//     const { name, value } = e.target;

//     if (value === "other") {
//       setShowSalutationPopup(true);
//     } else {
//       setGuestDetails({
//         ...guestDetails,
//         salutation: e.target.value,
//       });
//       setShowSalutationPopup(false);
//     }
//   };

//   const [popUpSalutation, setPopUpSalutation] = useState("");

//   const handlePopupAddButtonClick = async (e) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add`,

//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           body: JSON.stringify({
//             name: popUpSalutation,
//           }),
//         }
//       );
//       const data = await response.json();
//       if (response.ok) {
//         setPopUpSalutation(false);
//         setPopUpSalutation("");
//         // fetchSalutations();
//       } else if (data.message === "Unauthorised.") {
//         navigate("/");
//       } else {
//         console.error("Failed to fetch salutations:", data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching salutations:", error);
//     }
//     setGuestDetails({
//       ...guestDetails,
//       salutation: popUpSalutation,
//     });
//     setShowSalutationPopup(false);
//   };
//   const checkKeyValidity = (dataArray, keyName) => {
//     for (const item of dataArray) {
//       // Check if the key is empty or equal to 0
//       if (!item[keyName] || item[keyName] === 0) {
//         showErrorToast(`Invalid value for ${keyName.replace(/_/g, " ")}`);
//         return true; // Stop further processing if an invalid value is found
//       }
//     }
//   };

//   const handleConfirmButtonClick = () => {
//     if (isConfirmDisabled()) {
//       showErrorToast("Please fill required Guest details.");
//       return;
//     }
//     if (bookingDataArray.length === 0) {
//       showErrorToast("Please add booking details");
//       return;
//     }

//     if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
//       return;
//     }
//     if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
//       return;
//     }

//     const roomInputData = bookingDataArray.map((item) => ({
//       room_id: item.room_id || "",
//       room_type_name: item.room_category || "",
//       room_plan_id: item.room_rate_details.rate_plan_id || "",
//       room_plan: item.room_rate_details?.rate_plan_type || "",
//       no_of_rooms: item.no_of_rooms || "",
//       no_nights: item.no_of_nights || "",
//       room_rate: item.room_rate_new,
//       no_adult: item.no_of_adults || 0,
//       no_child: item.no_of_child || 0,
//       extra_adult: item.extra_adults || 0,
//       discount_rate: item?.discount_type?.discount_amount || 0,
//       discount_amount: item?.discountAmount || 0,
//       tax_rate: item.tax_rate || 0,
//       tax_amount: item.taxAmount || 0,
//       room_total_amount: item.subTotal || 0,
//       booking_paxes: item.booking_paxes,
//       room_grand_total: item.grandTotal,
//     }));

//     const addOns = addOnsPaymentData.addOn;

//     const paymentData = addOnsPaymentData.payments;

//     const postData = {
//       status: "confirm",
//       source: bookingData.booking_source,
//       sales_agent: bookingData.agent_name,
//       ota_source: bookingData.ota_source,
//       hold_inventory_id: ID,
//       booking_source_name: bookingData.booking_source_name,
//       check_in: bookingData.check_in,
//       check_out: bookingData.check_out,
//       date: currentDate,
//       agent_id: bookingData.agent_id,
//       guest_name: guestDetails.salutation + " " + guestDetails.name,
//       guest_email: guestDetails.email,
//       guest_phone: guestDetails.phone,
//       remarks: guestDetails.remarks,
//       pincode: guestDetails.pincode || "",
//       guest_city: guestDetails.city || "",
//       guest_state: guestDetails.state || "",
//       guest_address: guestDetails.address || "",
//       gst_type: bookingData.tax_type || "",
//       grand_total: sumGrandTotal,
//       taxable_amount: sumSubTotal,
//       total_tax_amount: sumTaxAmount,
//       total_discount_amount: sumDiscountAmount,
//       roomInputData: roomInputData,
//       addOns: addOns,
//       payments: paymentData,
//     };

//     // navigate("/bookings/createbooking/edit")

//     // Check child age for each booking pax
//     for (const booking of bookingDataArray) {
//       for (const pax of booking.booking_paxes) {
//         if (pax.pax_type === "child" && !pax.child_age) {
//           showErrorToast("Please enter child's age.");
//           return;
//         }
//       }
//     }

//     try {
//       fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/agent/bookings/store/new/bookings/${property_id.id}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           body: JSON.stringify({
//             ...postData,
//             check_in: checkIn,
//             check_out: checkOut,
//             property_current_date:
//               propertyCurrentDate + " " + currentTimeToBackend,
//           }),
//         }
//       )
//         .then((response) => {
//           if (!response.ok) {
//             showErrorToast("Error while creating booking");
//           }
//           return response.json();
//         })
//         .then((data) => {
//           if (data.success === true) {
//             // navigate("/bookings")
//             showSuccessToast("Booking Created Successfully");

//             navigate(`/bookings`);
//           } else {
//             showErrorToast("Error while creating booking");
//           }
//         });
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   function calculateDiscountAmountForBookingPaxes(bookingData) {
//     let totalDiscountAmount = 0;
//     // Loop through each booking in the array
//     bookingData?.forEach((booking) => {
//       // Loop through each booking pax in the booking
//       booking?.booking_paxes?.forEach((pax) => {
//         // Add the discount_amount of the booking pax to the totalDiscountAmount
//         totalDiscountAmount += pax?.discount_amount;
//       });
//     });
//     return totalDiscountAmount;
//   }

//   const [propertyCurrentDate, setPropertyCurrentDate] = useState();

//   useEffect(() => {
//     setPropertyCurrentDate(formatDateYYYYMMDD(new Date()));
//     setHoldBookingData({
//       hold_time: "",
//       hold_date: formatDateYYYYMMDD(new Date()),
//       hold_reason: "",
//     });
//   }, []);

//   useEffect(() => {
//     if (
//       bookingData.booking_source === "Walk In Booking" ||
//       bookingData.booking_source === "Front Desk"
//     ) {
//       setBookingData({
//         ...bookingData,
//         booking_source_name: guestDetails.name,
//       });
//     }
//     if (bookingData.booking_source === "Sales and Marketing") {
//       setBookingData({
//         ...bookingData,
//         booking_source_name: bookingData.agent_name,
//       });
//     }
//     if (bookingData.booking_source === "OTA") {
//       setBookingData({
//         ...bookingData,
//         booking_source_name: bookingData.ota_source,
//       });
//     }
//   }, [
//     bookingData.booking_source,
//     guestDetails.name,
//     bookingData.agent_name,
//     bookingData.ota_source,
//   ]);

//   function getCurrentTime() {
//     const now = new Date();
//     let hours = now.getHours();
//     let minutes = now.getMinutes();

//     hours = hours < 10 ? "0" + hours : hours;
//     minutes = minutes < 10 ? "0" + minutes : minutes;

//     return `${hours}:${minutes}`;
//   }
//   const holdBookingDataChange = (e) => {
//     setHoldBookingData({
//       ...holdBookingData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const holdBookingTimeDataChange = (e) => {
//     const { name, value } = e.target;
//     const propertyDate = propertyCurrentDate;
//     const selectedDate = holdBookingData.hold_date;

//     if (holdBookingData.hold_date === "") {
//       showErrorToast("Please select hold date");
//       return;
//     }

//     if (propertyCurrentDate === selectedDate) {
//       const currentTime = getCurrentTime();
//       const selectedTime = value;

//       if (selectedTime > currentTime) {
//         setHoldBookingData({
//           ...holdBookingData,
//           [name]: value,
//         });
//         return;
//       } else {
//         setHoldBookingData({
//           ...holdBookingData,
//           [name]: "",
//         });
//         showErrorToast("hold time should be greater than current time");
//         return;
//       }
//     } else if (selectedDate > propertyDate) {
//       setHoldBookingData({
//         ...holdBookingData,
//         [name]: value,
//       });
//       return;
//     } else {
//       setHoldBookingData({
//         ...holdBookingData,
//         [name]: "",
//       });
//       return;
//     }
//   };

//   // hold booking data submit
//   const handleAssignButtonClick = () => {
//     for (const booking of bookingDataArray) {
//       for (const pax of booking.booking_paxes) {
//         if (pax.pax_type === "child" && !pax.child_age) {
//           showErrorToast("Please enter child's age.");
//           return;
//         }
//       }
//     }
//     if (holdBookingData.hold_date === "") {
//       showErrorToast("please enter the hold date");
//       return;
//     }
//     if (holdBookingData.hold_time === "") {
//       showErrorToast("please enter the hold time");
//       return;
//     }
//     if (holdBookingData.hold_reason === "") {
//       showErrorToast("please enter reason for holding the booking");
//       return;
//     }
//     if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
//       return;
//     }
//     if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
//       return;
//     }

//     const roomInputData = bookingDataArray.map((item) => ({
//       room_id: item.room_id || "",
//       room_type_name: item.room_category || "",
//       room_plan_id: item.room_rate_details.rate_plan_id || "",
//       room_plan: item.room_rate_details?.rate_plan_type || "",
//       no_of_rooms: item.no_of_rooms || "",
//       no_nights: item.no_of_nights || "",
//       room_rate: item.room_rate_new,
//       no_adult: item.no_of_adults || 0,
//       no_child: item.no_of_child || 0,
//       extra_adult: item.extra_adults || 0,
//       discount_rate: item?.discount_type?.discount_amount || 0,
//       discount_amount: item?.discountAmount || 0,
//       tax_rate: item.tax_rate || 0,
//       tax_amount: item.taxAmount || 0,
//       room_total_amount: item.subTotal || 0,
//       booking_paxes: item.booking_paxes,
//       room_grand_total: item.grandTotal,
//     }));

//     const addOns = addOnsPaymentData.addOn;

//     const paymentData = addOnsPaymentData.payments;

//     const postData = {
//       status: "hold",
//       hold_time: holdBookingData.hold_time,
//       hold_date: holdBookingData.hold_date,
//       hold_reason: holdBookingData.hold_reason,
//       source: bookingData.booking_source,
//       sales_agent: bookingData.agent_name,
//       ota_source: bookingData.ota_source,

//       booking_source_name: bookingData.booking_source_name,
//       check_in: bookingData.check_in,
//       check_out: bookingData.check_out,
//       date: currentDate,
//       agent_id: bookingData.agent_id,
//       guest_name: guestDetails.salutation + " " + guestDetails.name,
//       guest_email: guestDetails.email,
//       guest_phone: guestDetails.phone,
//       remarks: guestDetails.remarks,
//       pincode: guestDetails.pincode || "",
//       guest_city: guestDetails.city || "",
//       guest_state: guestDetails.state || "",
//       guest_address: guestDetails.address || "",
//       gst_type: bookingData.tax_type || "",
//       grand_total: sumGrandTotal,
//       taxable_amount: sumSubTotal,
//       total_tax_amount: sumTaxAmount,
//       total_discount_amount: sumDiscountAmount,
//       roomInputData: roomInputData,
//       addOns: addOns,
//       payments: paymentData,
//     };

//     try {
//       fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/store/new/bookings/${property_id.id}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           body: JSON.stringify({
//             ...postData,
//             check_in: checkIn,
//             check_out: checkOut,
//             property_current_date:
//               propertyCurrentDate + " " + currentTimeToBackend,
//           }),
//         }
//       )
//         .then((response) => {
//           if (!response.ok) {
//             showErrorToast("Error while creating booking");
//           }
//           return response.json();
//         })
//         .then((data) => {
//           if (data.success === true) {
//             // navigate("/bookings")
//             showSuccessToast("Booking Created Successfully");

//             // navigate(`/bookings/createbooking/edit/${data.booking_id}`);
//             navigate(`/bookings`);
//           } else {
//             showErrorToast("Error while creating booking");
//           }
//         });
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   // array for tracking the show adhoc room rates form child to show the discount
//   const [adhocArray, setAdhocArray] = useState([]);

//   const getAdhocArrayFromChild = (data, index) => {
//     setAdhocArray((prev) => {
//       const newArray = [...prev];
//       newArray[index] = data;
//       return newArray;
//     });
//   };

//   // discount type

//   const handleDiscountTypeChange = (e) => {
//     const id = e?.target?.value;

//     const discount = discountOptions?.find((item) => item?.id == id);
//     setBookingData({
//       ...bookingData,
//       discount_type: discount,
//     });
//   };

//   useEffect(() => {
//     if (adhocArray.includes(true)) {
//       setBookingData({
//         ...bookingData,
//         discount_type: null,
//       });
//     }
//   }, [adhocArray, setBookingData]);

//   return (
//     <div className="main_container">
//       <div className="create_booking_page_main">
//         <div
//           className="create_booking_page_top flex align_center"
//           style={{ padding: "5px 0" }}
//         >
//           <div className="flex align_center">
//             <CreateBookingSelectBox
//               label="Booking Type"
//               value={bookingData.booking_type}
//               onChange={handleBookingTypeChange}
//               options={BookingTypeOptions}
//               disabled={bookingDataArray.length > 0}
//               error={errors.booking_type}
//             />

//             <div
//               className="create_booking_selectBox_container"
//               onClick={() => setErrors({ no_of_nights: false })}
//             >
//               <DateRangePickerNew
//                 dataFromChild={dataFromChild}
//                 minDate={new Date(bookingData?.hold_from_date)}
//                 maxDate={new Date(bookingData?.hold_to_date)}
//                 disabled={
//                   bookingDataArray.length > 0 ||
//                   bookingData.room_category !== ""
//                 }
//                 error={errors.no_of_nights}
//               />
//             </div>

//             {/* room category */}

//             <CreateBookingSelectBoxOptionDisabled
//               // disabled={
//               //   bookingData.check_in && bookingData.check_out ? false : true
//               // }
//               label="Room Category"
//               value={bookingData.room_category}
//               onChange={handleRoomCategory}
//               options={RoomCategoryOptions}
//               error={errors.room_category}
//               bookingDataArray={bookingDataArray}
//             />

//             <CreateBookingInputBox
//               alignment="vertical"
//               label="No. of Nights"
//               disabled={true}
//               value={bookingData.no_of_nights}
//               error={errors.no_of_nights}
//             />
//             <CreateBookingInputBox
//               alignment="vertical"
//               label="No. of Rooms"
//               onChange={handleNumberOfRoomsChange}
//               name="no_of_rooms"
//               value={bookingData.no_of_rooms}
//               error={errors.no_of_rooms}
//               disabled={bookingData.room_category === ""}
//             />
//             <CreateBookingInputBox
//               alignment="vertical"
//               label="No. of Adults"
//               onChange={handleNumberOfAdultChange}
//               name="no_of_adults"
//               value={bookingData.no_of_adults}
//               error={errors.no_of_adults}
//               disabled={bookingData.room_category === ""}
//             />
//             <CreateBookingInputBox
//               alignment="vertical"
//               label="Extra Adults"
//               onChange={handleNumberOfExtraAdultChange}
//               name="extra_adults"
//               value={bookingData.extra_adults}
//               error={errors.extra_adult}
//               disabled={
//                 bookingData.room_category === "" ||
//                 bookingData.no_of_adults === ""
//               }
//             />
//             <CreateBookingInputBox
//               alignment="vertical"
//               label="No. of Child"
//               name="no_of_child"
//               onChange={handleNumberOfChildChange}
//               value={bookingData.no_of_child}
//               error={errors.no_of_child}
//               disabled={
//                 bookingData.room_category === "" ||
//                 bookingData.no_of_adults === ""
//               }
//             />
//           </div>
//           <div>
//             {availableRooms.available_rooms !== undefined && (
//               <p
//                 className="avail_room"
//                 style={{
//                   fontSize: "12px",
//                   backgroundColor:
//                     availableRooms.available_rooms === 0
//                       ? "rgb(255 0 0 / 18%)"
//                       : "",
//                   color: availableRooms.available_rooms === 0 ? "red" : "",
//                 }}
//               >
//                 {availableRooms.available_rooms === 0
//                   ? "Rooms not available"
//                   : `Available Rooms: ${availableRooms.available_rooms}`}
//               </p>
//             )}

//             {(availableRooms.base_adult !== undefined ||
//               availableRooms.base_adult === "") && (
//               <p style={{ fontSize: "10px" }}>
//                 Base Occupancy: {availableRooms.base_adult}
//               </p>
//             )}

//             {(availableRooms.max_adult !== undefined ||
//               availableRooms.max_adult === "") && (
//               <p style={{ fontSize: "10px" }}>
//                 Max. Occupancy: {availableRooms.max_adult}
//               </p>
//             )}

//             {(availableRooms.allowedExtraAdult !== undefined ||
//               availableRooms.allowedExtraAdult === "") && (
//               <p
//                 style={{
//                   fontSize: "10px",
//                   color: availableRooms.allowedExtraAdult ? "green" : "red",
//                 }}
//               >
//                 Extra Adult Allowed:{" "}
//                 {availableRooms.allowedExtraAdult
//                   ? `Enabled (${availableRooms.allowedExtraAdult})`
//                   : "Disabled"}
//               </p>
//             )}

//             {(availableRooms.max_child !== undefined ||
//               availableRooms.max_child === "") && (
//               <p style={{ fontSize: "10px" }}>
//                 Child Allowed: {availableRooms.max_child}
//               </p>
//             )}
//             <></>
//           </div>
//         </div>
//         <div className="create_booking_page_mid flex gap align_items_end">
//           <CreateBookingSelectBox
//             label="Booking Source"
//             value={bookingData?.booking_source}
//             onChange={handleBookingSourceChange}
//             options={BookingSourceOptions}
//             disabled={bookingDataArray.length > 0}
//             error={errors.booking_source}
//           />

//           {bookingData.booking_source === "Agent (B2B) Partner" && (
//             <label>
//               Company Name <br />
//               <select
//                 onChange={(e) => handleInputSelectAgentChange(e)}
//                 name="agent_id"
//                 className={
//                   errors.agent_id
//                     ? "createBookingInputAndSelectTags create_booking_error-border"
//                     : "createBookingInputAndSelectTags"
//                 }
//                 style={{
//                   border: errors.agent_id
//                     ? "1px solid #E03838"
//                     : "1px solid #ddd",
//                   backgroundColor: errors.agent_id ? "#FFEAEA" : "",
//                 }}
//               >
//                 <option>Select</option>
//                 {agentList.map((item, index) => {
//                   return (
//                     <option key={index} value={item.agent_information}>
//                       {item.company_name}
//                     </option>
//                   );
//                 })}
//               </select>
//             </label>
//           )}
//           {bookingData.booking_source === "OTA" && (
//             <label>
//               Select OTA <br />
//               <select
//                 onChange={handleInputOtaChange}
//                 name="ota_source"
//                 className={
//                   errors.ota_source
//                     ? "createBookingInputAndSelectTags create_booking_error-border"
//                     : "createBookingInputAndSelectTags"
//                 }
//                 style={{
//                   border: errors.ota_source
//                     ? "1px solid #E03838"
//                     : "1px solid #ddd",
//                   backgroundColor: errors.ota_source ? "#FFEAEA" : "",
//                 }}
//                 value={bookingData.ota_source}
//               >
//                 <option value={""}>Select</option>
//                 {otaTypeOptions.map((item, index) => {
//                   return (
//                     <option key={index} value={item.value}>
//                       {item.label}
//                     </option>
//                   );
//                 })}
//               </select>
//             </label>
//           )}
//           {bookingData.agent_id !== "" &&
//             bookingData.agent_id &&
//             bookingData.booking_source === "Agent (B2B) Partner" && (
//               <div>
//                 <label>TAC Disc. %</label> <br />
//                 <input
//                   type="number"
//                   name="tac_discount"
//                   disabled
//                   value={bookingData?.tac_discount}
//                   className="createBookingInputAndSelectTags"
//                 />
//               </div>
//             )}
//           {bookingData.booking_source === "Sales and Marketing" && (
//             <div>
//               <label>Sales Agent Name</label> <br />
//               <input
//                 type="name"
//                 name="agent_name"
//                 onChange={handleInputAgentNameChange}
//                 value={bookingData?.agent_name}
//                 className={
//                   errors.agent_name
//                     ? " createBookingInputAndSelectTags create_booking_error-border"
//                     : "createBookingInputAndSelectTags"
//                 }
//                 style={{
//                   border: errors.agent_name
//                     ? "1px solid #E03838"
//                     : "1px solid #ddd",
//                   backgroundColor: errors.agent_name ? "#FFEAEA" : "",
//                 }}
//               />
//             </div>
//           )}

//           <div>
//             <BgThemeButton
//               onClick={handleClickOnAddButton}
//               type={"submit"}
//               children={"Add Room"}
//               disabled={
//                 availableRooms !== "" && availableRooms.available_rooms === 0
//                   ? true
//                   : false
//               }
//               style={{
//                 background:
//                   availableRooms !== "" && availableRooms.available_rooms === 0
//                     ? "#6483db"
//                     : "",
//                 cursor:
//                   availableRooms !== "" && availableRooms.available_rooms === 0
//                     ? "not-allowed"
//                     : "",
//               }}
//             />
//           </div>
//         </div>
//       </div>

//       <div
//         style={{
//           display: "flex",
//           margin: "10px 0",
//           justifyContent: "space-between",
//         }}
//       >
//         <div className="create_booking_page_left_booking_card_container">
//           {bookingDataArray.length > 0 && (
//             <>
//               {bookingDataArray.map((data, index) => {
//                 return (
//                   <React.Fragment key={index}>
//                     <AgentHoldRoomsCreateBookingCard
//                       index={index}
//                       singleBookingData={data}
//                       setBookingDataArray={setBookingDataArray}
//                       bookingDataArray={bookingDataArray}
//                       handleDeleteBookingCard={handleDeleteBookingCard}
//                       discount_type={bookingData.discount_type}
//                       tax_type={bookingData.tax_type}
//                       getAdhocArrayFromChild={getAdhocArrayFromChild}
//                       adhocArray={adhocArray}
//                       property_id={property_id}
//                     />
//                   </React.Fragment>
//                 );
//               })}
//             </>
//           )}

//           {/* guest details */}

//           <div className="create_booking_primary_guest_details_container_wrapper">
//             <div className="create_booking_primary_guest_details_container">
//               <h4>Primary Guest Details</h4>
//               <div className="create_booking_primary_guest_details">
//                 <div className="create_booking_primary_guest_details_input_container">
//                   <label className="standard-select">
//                     Name <span style={{ color: "red" }}>*</span> <br />
//                   </label>
//                   <div className="flex gap_5">
//                     <select
//                       id="salutation"
//                       name="salutation"
//                       value={guestDetails.salutation}
//                       onChange={handleSalutationChange}
//                       className="create_booking_primary_guest_details_input"
//                       style={{
//                         width: "50px",
//                         marginRight: "5px",
//                         background: errors.guest_name ? "#FFEAEA" : "",
//                         border: errors.guest_name ? "1px solid #E03838" : "",
//                       }}
//                     >
//                       <option value="">Select...</option>
//                       {salutations?.map((salutation, index) => (
//                         <option key={index} value={salutation.name}>
//                           {salutation.name}
//                         </option>
//                       ))}
//                       <option value={"other"}>Other</option>
//                     </select>

//                     {showSalutationPopup && (
//                       <input
//                         type="text"
//                         required
//                         className="create_booking_primary_guest_details_input"
//                         name="salutation"
//                         value={guestDetails.salutation}
//                         onChange={handleGuestDetails}
//                         style={{ width: "100px", marginRight: "5px" }}
//                       />
//                     )}

//                     <input
//                       type="text"
//                       required
//                       className="create_booking_primary_guest_details_input"
//                       name="name"
//                       value={guestDetails.name}
//                       onChange={handleGuestDetails}
//                       style={{
//                         background: errors.guest_name ? "#FFEAEA" : "",
//                         border: errors.guest_name ? "1px solid #E03838" : "",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <div className="create_booking_primary_guest_details_input_container">
//                   <label className="standard-select">Phone </label> <br />
//                   <input
//                     type="number"
//                     required
//                     className="create_booking_primary_guest_details_input"
//                     value={guestDetails.phone}
//                     name="phone"
//                     onChange={handleGuestDetails}
//                   />
//                 </div>

//                 <div className="create_booking_primary_guest_details_input_container">
//                   <label className="standard-select">Email </label> <br />
//                   <input
//                     type="email"
//                     className="create_booking_primary_guest_details_input"
//                     value={guestDetails.email}
//                     name="email"
//                     onChange={handleGuestDetails}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="create_booking_primary_guest_details_container">
//               <h4>Remarks</h4>
//               <div className="create_booking_primary_guest_details">
//                 <textarea
//                   type="text"
//                   required
//                   className="create_booking_primary_guest_details_input_remarks"
//                   name="remarks"
//                   value={guestDetails.remarks}
//                   onChange={handleGuestDetails}
//                   style={{ width: "100%", resize: "none", padding: "5px" }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* total amount Card   */}

//         <div className="create_booking_page_total_amount_card">
//           {/* setting the discount type and tax type changing these will change the total amount */}
//           {/* this select tag is sending id to handler and it sets discount type object in BookingData object */}
//           <div
//             style={{
//               display: "flex",
//               // justifyContent: "space-between",
//               gap: "10px",
//               flexWrap: "wrap",
//               padding: "20px",
//               borderBottom: "0.5px solid #333",
//             }}
//           >
//             <div>
//               {!adhocArray.includes(true) && (
//                 <>
//                   <label>Disc Type</label> <br />
//                   <select
//                     onChange={handleDiscountTypeChange}
//                     name="discount_id"
//                     className="createBookingInputAndSelectTags"
//                     style={{ marginTop: "5px", width: "150px" }}
//                     value={bookingData?.discount_type?.id}
//                   >
//                     <option value="">Select</option>

//                     {discountOptions && discountOptions.length > 0 ? (
//                       discountOptions.map((item, index) => (
//                         <option key={index} value={item.id}>
//                           {item.discount_name} {item.discount_amount}
//                         </option>
//                       ))
//                     ) : (
//                       <option disabled>No discount added</option>
//                     )}
//                   </select>
//                 </>
//               )}
//             </div>
//             <div>
//               <label className="Create_booking_label">Tax</label>
//               <div className="flex gap_7">
//                 <label className="Create_booking_label flex gap_7">
//                   <input
//                     type="radio"
//                     name="tax_type"
//                     value="inclusive"
//                     checked={bookingData.tax_type === "inclusive"}
//                     onChange={handleInputChange}
//                   ></input>
//                   Inclusive
//                 </label>
//                 <label className="Create_booking_label flex gap_7">
//                   <input
//                     type="radio"
//                     name="tax_type"
//                     value={"exclusive"}
//                     checked={bookingData.tax_type === "exclusive"}
//                     onChange={handleInputChange}
//                   ></input>
//                   Exclusive
//                 </label>
//               </div>
//             </div>
//           </div>
//           <div style={{ padding: "20px", position: "relative" }}>
//             <div className="crate_booking_page_total_amount_heading">
//               <span>Subtotal</span>
//               <span>
//                 {formatIndianCurrency(+sumSubTotal + +sumSubtotalAddons)}{" "}
//               </span>
//             </div>
//             <div className="crate_booking_page_total_amount_heading">
//               <span>Discount Amt.</span>
//               <span>{formatIndianCurrency(+sumDiscountAmount)}</span>
//             </div>

//             <div className="crate_booking_page_total_amount_heading">
//               <span>Tax Amt.</span>
//               <span>
//                 {formatIndianCurrency(+sumTaxAmount + +sumTaxAmountAddons)}{" "}
//               </span>
//             </div>
//             <div
//               className="crate_booking_page_total_amount_heading"
//               style={{ color: "#3968ED" }}
//             >
//               <span>Grand Total</span>
//               <span>
//                 {formatIndianCurrency(+sumGrandTotal + +sumGrandTotalAddons)}
//               </span>
//             </div>

//             <div
//               className="crate_booking_page_total_amount_heading"
//               style={{ color: "#15AA12" }}
//             >
//               <h4>Paid Amt.</h4>
//               <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
//             </div>
//             <div
//               className="crate_booking_page_total_amount_heading"
//               style={{ color: "#BD941B" }}
//             >
//               <h4>Due Amt.</h4>
//               <h3>
//                 {formatIndianCurrency(
//                   +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
//                 )}
//               </h3>
//             </div>
//           </div>

//           <div className="flex gap_7" style={{ padding: "20px" }}>
//             <BgThemeButton
//               onClick={handleConfirmButtonClick}
//               type={"submit"}
//               children={"Confirm"}
//             />

//             {/* <BgThemeButton
//               style={{
//                 background: "#BD941B",
//                 cursor: bookingDataArray.length < 1 ? "not-allowed" : "",
//               }}
//               onClick={() => setShowHoldPopup(true)}
//               disabled={bookingDataArray.length < 1}
//             >
//               Hold
//             </BgThemeButton> */}
//           </div>

//           {showHoldPopup && (
//             <>
//               <div
//                 className="createBookingTableNewPopupContainer"
//                 onClick={() => setShowHoldPopup(false)}
//               >
//                 <div
//                   className="createBookingTableNewPopupContent"
//                   onClick={(e) => e.stopPropagation()}
//                   style={{ width: "400px" }}
//                 >
//                   <h3 style={{ textAlign: "center" }}>Hold Date & Time</h3>

//                   <div className="d_flex gap_7" style={{ margin: "15px 0" }}>
//                     <div style={{ width: "48%" }}>
//                       <label>Select Date</label> <br />
//                       <div style={{ position: "relative" }}>
//                         <input
//                           type="date"
//                           className="addEvent-input"
//                           min={propertyCurrentDate}
//                           name="hold_date"
//                           style={{ cursor: "pointer" }}
//                           value={holdBookingData.hold_date}
//                           onChange={holdBookingDataChange}
//                           placeholder="Enter Date"
//                         ></input>
//                         <input
//                           type="text"
//                           className="addEvent-input"
//                           name="hold_date"
//                           value={formatDate(holdBookingData.hold_date) || ""}
//                           onChange={holdBookingDataChange}
//                           placeholder="dd/mm/yyyy"
//                           style={{
//                             position: "absolute",
//                             width: "50%",
//                             top: "2px",
//                             left: "2px",
//                             border: "none",
//                             outline: "none",
//                           }}
//                         ></input>
//                       </div>
//                     </div>
//                     <div style={{ width: "48%" }}>
//                       <label>Select Time</label> <br />
//                       {/* <select
//                         className="addEvent-input"
//                         name="hold_time"
//                         value={holdBookingData.hold_time}
//                         onChange={holdBookingDataChange}
//                       >
//                         {timeArray.map((item, index) => {
//                           return (
//                             <option key={index} value={item.value}>
//                               {item.label}
//                             </option>
//                           );
//                         })}
//                       </select> */}
//                       <input
//                         type="time"
//                         className="addEvent-input"
//                         name="hold_time"
//                         value={holdBookingData.hold_time}
//                         onChange={holdBookingTimeDataChange}
//                         min={getCurrentTime()}
//                       />
//                     </div>
//                   </div>

//                   <div>
//                     <label>Reason</label> <br />
//                     <textarea
//                       className="addEvent-input"
//                       name="hold_reason"
//                       onChange={holdBookingDataChange}
//                       style={{ width: "100%", resize: "none", padding: "5px" }}
//                     />
//                   </div>

//                   <div className="createBookingTableNewPopupFooter">
//                     <BgThemeButton
//                       // type={"submit"}
//                       children={"Assign"}
//                       style={{ background: "#BD941B", width: "48%" }}
//                       onClick={handleAssignButtonClick}
//                     />
//                     <BgOutlineThemeButton
//                       //  type={"submit"}
//                       children={"Discard"}
//                       onClick={() => setShowHoldPopup(false)}
//                       style={{ width: "48%" }}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default AgentHoldRoomsCreateBooking;


import React from "react";
import CreateBookingSelectBox from "../../CreateBookingNewPage/CreateBookingSelectBox/CreateBookingSelectBox";
import DateRangePickerNew from "../../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import "../../CreateBookingNewPage/CreateBookingNewPage.css";
import CreateBookingSelectBoxOptionDisabled from "../../CreateBookingNewPage/createBookingSelectBoxOptionDisabled/CreateBookingSelectBoxOptionDisabled";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AgentHoldRoomsCreateBookingCard from "./AgentHoldRoomsCreateBookingCard";
import CreateBookingInputBox from "../../CreateBookingNewPage/CreateBookingInputBox/CreateBookingInputBox";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import {
  formatDateYYYYMMDD,
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../../utils/utils";


const AgentHoldRoomsCreateBooking = () => {
  const { ID } = useParams();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const agent = JSON.parse(localStorage.getItem("agentDetails"));

  const property_id = JSON.parse(localStorage.getItem("selectedProperty"))

  const navigate = useNavigate();

  const currentTimeToBackend = useCurrentTime("24");

  //..date range ....
  const currentDate = new Date().toISOString().split("T")[0];

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const dataFromChild = (from_date, to_date) => {
    // setErrors((prev) => ({ ...prev, no_of_nights: false }));

    setCheckIn(from_date);
    setCheckOut(to_date);
    setBookingData((prevData) => ({
      ...prevData,
      check_in: from_date,
      check_out: to_date,
    }));
  };

  const BookingTypeOptions = [
    { room_name: "Single", value: "single" },
    { room_name: "Multiple", value: "multiple" },
  ];
  // calculate the number of nights from check in check out

  const [bookingData, setBookingData] = useState({
    booking_type: "",
    available_rooms: "",
    extra_adult_allowed: "",
    check_out: null,
    check_in: null,
    no_of_nights: "",
    no_of_adults: "",
    extra_adults: "",
    no_of_child: "",
    no_of_rooms: "",
    room_category: "",
    room_id: "",
    booking_source: "Agent (B2B) Partner",
    agent_id: agent.details.agent_information,
    discount: "",
    tax_type: "inclusive",
    tac_discount: agent?.details.category_details?.agent_cat_discount,
    selected_discount: "",
    room_plans: "",
    ota_source: "",
    agent_name: agent?.agent_name,
  });
  console.log(bookingData, "booking data")
  useEffect(() => {
    const calculateNumberOfNights = () => {
      const checkIn = new Date(bookingData.check_in);
      const checkOut = new Date(bookingData.check_out);
      const timeDifference = checkOut.getTime() - checkIn.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return numberOfNights;
    };

    const numberOfNightsForBooking = calculateNumberOfNights();

    setBookingData((prevData) => ({
      ...prevData,
      no_of_nights: numberOfNightsForBooking,
    }));
  }, [bookingData.check_in, bookingData.check_out]);

  //handle booking change
  const handleBookingTypeChange = (event) => {
    setErrors((prev) => ({ ...prev, booking_type: false }));
    setBookingData({
      ...bookingData,
      booking_type: event.target.value,
    });
  };

  //   ................Room Category Amd fetching room plans.....................

  const [RoomCategoryOptions, setRoomCategoryOptions] = useState("");

  const fetchRoomTypeLists = async () => {
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/agent/hold/inventory/details/20/1

        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/hold/inventory/details/${ID}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.data.roomDetails);
      setBookingData((prev) => ({
        ...prev,
        hold_from_date: data.data.from_date,
        hold_to_date: data.data.to_date,
        agent_name: data.data.reference_person_name,
        agent_id: data.data.reference_id,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id?.id) {
      fetchRoomTypeLists();
    }
  }, [property_id?.id]);

  const handleRoomCategory = async (e) => {
    if (e.target.value === "") {
      setBookingData((perv) => ({
        ...perv,
        room_category: "",
        limits: "",
      }));
      setAvailableRooms("");

      return;
    }
    setBookingData((prev) => ({
      ...prev,
      no_of_rooms: "",
      no_of_adults: "",
      no_of_child: "",
      extra_adults: "",
    }));

    setErrors((prev) => ({ ...prev, room_category: false }));
    const id = RoomCategoryOptions.find(
      (item) => item?.room_name === e.target.value
    )?.room_id;
    const roomCategory = e.target.value;
    // api  for fetching the room plans

    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent-dashboard/check/hold/rooms/availability/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}&room_id=${id}&id=${ID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      const blockedRooms = data?.available_rooms_dates?.filter(
        (item) => item?.blocked !== 0
      );
      // if (blockedRooms?.length > 0) {
      //   showErrorToast(
      //     `Room is blocked for selected dates :  ${blockedRooms
      //       .map((room) => room.date)
      //       .join(", ")}      `
      //   );
      //   return;
      // } else {
      setAvailableRooms(data);
      setBookingData((prev) => ({
        ...prev,
        limits: data,
      }));

      //  fetch room plans
      fetchRoomRates(id, roomCategory);
      // }

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomRates = async (id, roomCategory) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();

      if (data.success === true) {
        setBookingData((prev) => ({
          ...prev,
          room_plans: data?.roomRates,
          room_category: roomCategory,
          room_id: id,
        }));
      }
    } catch (error) {
      console.error("Error fetching room plans:", error);
      showErrorToast("something went wrong please try again...");
    }
  };

  const [availableRooms, setAvailableRooms] = useState("");

  const handleInputChange = (e) => {
    const extraBeddingAllowed =
      availableRooms?.extra_bedding_allowed == "enabled" ? true : false;

    const { name, value } = e.target;

    if (name === "agent_id") {
      const findAgent = agentList.find(
        (item) => item.agent_information == value
      );

      setBookingData({
        ...bookingData,
        [name]: value,
        booking_source_name: findAgent?.agent_name,
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
        tac_discount: 0,
      });
    }
  };
  const handleInputOtaChange = (e) => {
    setErrors((prev) => ({ ...prev, ota_source: false }));
    const { name, value } = e.target;
    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };

  const handleInputSelectAgentChange = (e) => {
    setErrors((prev) => ({ ...prev, agent_id: false }));
    const { name, value } = e.target;

    // if

    const selectedAgent = agentList.find(
      (item) => +item.agent_information === +value
    );
    const agentName = selectedAgent ? selectedAgent.company_name : "";

    setBookingData({
      ...bookingData,
      [name]: value,
      booking_source_name: agentName,
    });
  };

  const handleInputAgentNameChange = (e) => {
    setErrors((prev) => ({ ...prev, agent_name: false }));
    const { name, value } = e.target;
    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };

  // booking submit handler
  const BookingSourceOptions = [
    { room_name: "Walk In Booking", value: "walk in booking" },
    { room_name: "Front Desk", value: "front desk" },
    { room_name: "Sales and Marketing", value: "sales and marketing" },
    { room_name: "Agent (B2B) Partner", value: "agent" },
    { room_name: "OTA", value: "ota" },
  ];

  const handleBookingSourceChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      booking_source: false,
      agent_id: false,
      agent_name: false,
    }));
    setBookingData({
      ...bookingData,
      booking_source: e.target.value,
      tac_discount: 0,
      agent_id: "",
      agent_name: "",
    });
  };

  //booking agent

  const [agentList, setAgentList] = useState([]);

  const fetchAgentList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAgentList(data.data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (bookingData.booking_source === "Agent (B2B) Partner" && property_id) {
  //     // fetchAgentList();
  //   }
  // }, [bookingData.booking_source, property_id]);

  // Tac discount
  const fetchTacDiscount = async () => {
    const agentId = bookingData.agent_id;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/tac/discount/${agentId}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setBookingData({
        ...bookingData,
        tac_discount: data.tac_discount,
      });
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (bookingData.agent_id &&  property_id) {
  //     fetchTacDiscount();
  //   }
  // }, [bookingData.agent_id, property_id]);

  // for discount percentage
  const [discountOptions, setDiscountOptions] = useState([]);
  
  const fetchDiscountOptions = async () => {
    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/discount/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setDiscountOptions(data.discount);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching discount options:", error);
    }
  };
  // useEffect(() => {
  //   if (bookingData.check_in && bookingData.check_out && property_id) {
  //     fetchDiscountOptions();
  //   }
  // }, [
  //   bookingData.check_in,
  //   bookingData.check_out,
  //   property_id,
  //   checkIn,
  //   checkOut,
  // ]);

  // handle click on Add button save booking data in Booking array
  const [bookingDataArray, setBookingDataArray] = useState([]);

  const [showDiscountSelect, setShowDiscountSelect] = useState(true);
  const handleDeleteBookingCard = (index) => {
    setBookingDataArray((prev) => prev.filter((_, i) => i !== index));
    setAdhocArray((prev) => prev.filter((_, i) => i !== index));
  };

  // ota sources
  const otaTypeOptions = [
    { label: "MakeMyTrip", value: "makemytrip" },
    { label: "GoIbibo", value: "goibibo" },
    { label: "Expedia", value: "expedia" },
    { label: "EaseMyTrip", value: "easemytrip" },
    { label: "ClearTrip", value: "cleartrip" },
    { label: "HappyEasyGo", value: "happyeasygo" },
    { label: "Travelguru", value: "travelguru" },
    { label: "Yatra", value: "yatra" },
    { label: "Agoda", value: "agoda" },
    { label: "Booking.com", value: "booking.com" },
  ];

  const [errors, setErrors] = useState({
    booking_type: false,
    no_of_nights: false,
    no_of_rooms: false,
    room_category: false,
    booking_source: false,
    agent_id: false,
    no_of_adults: false,
    discount_type: false,
    agent_id: false,
    agent_name: false,
    ota_source: false,
    agent_name: false,
    extra_adult: false,
    no_of_child: false,
    guest_name: false,
  });

  const handleClickOnAddButton = () => {
    if (isDisabled()) {
      return;
    }
    // const blockedRooms = availableRooms?.available_rooms_dates?.filter(
    //   (item) => item?.blocked !== 0
    // );
    // if (blockedRooms?.length > 0) {
    //   showErrorToast(
    //     `Room is blocked for selected dates :  ${blockedRooms
    //       .map((room) => room.date)
    //       .join(", ")}      `
    //   );
    //   return;
    // }

    const hasSingleBooking = bookingDataArray.some(
      (item) => item.booking_type === "Single"
    );

    const hasSameRoomCategory = bookingDataArray.some(
      (item) => item.room_category === bookingData.room_category
    );
    if (hasSameRoomCategory) {
      showErrorToast("Same room category cannot be added twice");
      return;
    }

    if (hasSingleBooking) {
      showErrorToast("Only one booking can be created at a time");
    } else {
      setBookingDataArray((prevData) => [...prevData, bookingData]);
      setAvailableRooms({});
      setTimeout(() => {
        setBookingData((prevData) => ({
          ...prevData,
          no_of_rooms: "",
          no_of_adults: "",
          extra_adults: "",
          extra_adult: "",
          no_of_child: "",
          room_category: "",
        }));
      }, 500);
      // setDiscountOptions([]);
    }
  };

  const isDisabled = () => {
    setTimeout(() => {
      setErrors((prev) => ({
        ...prev,
        booking_type: false,
        no_of_nights: false,
        no_of_rooms: false,
        room_category: false,
        booking_source: false,
        agent_id: false,
        no_of_adults: false,
        discount_type: false,
        agent_name: false,
        ota_source: false,
        extra_adult: false,
        no_of_child: false,
        guest_name: false,
      }));
    }, 5000);

    if (
      bookingData.booking_type === "" ||
      bookingData.no_of_nights === "" ||
      bookingData.no_of_rooms === "" ||
      bookingData.room_category === "" ||
      bookingData.booking_source === ""
    ) {
      showErrorToast("Please fill in all required fields.");
      if (bookingData.booking_type === "") {
        setErrors((prev) => ({ ...prev, booking_type: true }));
      }
      if (bookingData.no_of_nights === 0) {
        setErrors((prev) => ({ ...prev, no_of_nights: true }));
      }
      if (bookingData.room_category === "") {
        setErrors((prev) => ({ ...prev, room_category: true }));
      }
      if (bookingData.booking_source === "") {
        setErrors((prev) => ({ ...prev, booking_source: true }));
      }
      if (bookingData.no_of_rooms === "") {
        setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      }
      if (bookingData.no_of_adults === "") {
        setErrors((prev) => ({ ...prev, no_of_adults: true }));
      }
      return true;
    }

    if (
      bookingData.booking_source === "Agent (B2B) Partner" &&
      bookingData.agent_id === ""
    ) {
      setErrors((prev) => ({ ...prev, agent_id: true }));
      showErrorToast("Please Select Agent for B2B bookings.");
      return true;
    }

    if (bookingData.booking_source === "OTA" && bookingData.ota_source === "") {
      setErrors((prev) => ({ ...prev, ota_source: true }));
      showErrorToast("Please provide the OTA Source for OTA bookings.");
      return true;
    }
    if (
      bookingData.booking_source === "Sales and Marketing" &&
      bookingData.agent_name === ""
    ) {
      setErrors((prev) => ({ ...prev, agent_name: true }));
      showErrorToast(
        "Please provide the Agent Name for Sales and Marketing bookings."
      );
      return true;
    }

    return false;
  };

  function calculateTotal(array, property) {
    // Check if the input is an array
    if (!Array.isArray(array)) {
      return 0;
    }

    return array.reduce((total, item) => {
      const value = parseFloat(item[property]) || 0; // Convert to number, default to 0 if NaN
      return total + value;
    }, 0);
  }

  const [guestDetails, setGuestDetails] = useState({
    salutation: "",
    name: "",
    email: "",
    phone: "",
    remarks: "",
  });

  // calculating the total of the booking arrays and saving them

  const sumSubTotal = calculateTotal(bookingDataArray, "subTotal") || 0;
  const sumGrandTotal = calculateTotal(bookingDataArray, "grandTotal");
  const sumDiscountAmount =
    calculateTotal(bookingDataArray, "discountAmount") || 0;

  const sumTaxAmount = calculateTotal(bookingDataArray, "taxAmount") || 0;

  // handle confirm booking button

  const isConfirmDisabled = () => {
    if (
      // guestDetails.salutation === "" ||
      guestDetails.name === ""
      // guestDetails.email === "" ||
      // guestDetails.phone === ""
    ) {
      setErrors((prev) => ({ ...prev, guest_name: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, guest_name: false }));
      }, 5000);
      showErrorToast("Please fill in all required fields.");
      return true;
    }
  };

  // on click of hold button show the hold modal

  const [showHoldPopup, setShowHoldPopup] = useState(false);

  const [holdBookingData, setHoldBookingData] = useState({
    hold_date: "",
    hold_time: "",
    hold_reason: "",
  });

  // function wrote  for showing the date format according to indian standard
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "dd/mm/yyyy";
    } else {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  };

  const [addOnsPaymentData, setAddOnsPaymentData] = useState({
    addOn: [],
    payments: [],
  });

  const sumPaidAmount =
    calculateTotal(addOnsPaymentData.payments, "amount") || 0;

  // add ons cost

  const sumSubtotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "price") || 0;

  const sumGrandTotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "total") || 0;

  const sumTaxAmountAddons =
    calculateTotal(addOnsPaymentData.addOn, "tax_amount") || 0;

  // new handler for room change
  const handleNumberOfRoomsChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
        no_of_adults: "",
        no_of_child: "",
        extra_adults: "",
      });
      setErrors((prev) => ({ ...prev, no_of_rooms: false }));
      return;
    }

    const selectedRooms = +value;
    const prevAvailableRooms = availableRooms?.available_rooms;

    if (selectedRooms <= 0) {
      showErrorToast("Please enter a valid number of rooms");
      setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      return;
    }

    if (selectedRooms > prevAvailableRooms) {
      showErrorToast(
        "Entered number cannot be greater than the number of available rooms"
      );
      setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_rooms: false }));
      }, 5000);
      return;
    }

    // Update the booking data with the new value
    setBookingData({
      ...bookingData,
      [name]: value,
      no_of_adults: "",
      no_of_child: "",
      extra_adults: "",
    });
    setErrors((prev) => ({ ...prev, no_of_rooms: false }));
  };

  // handle number of adult
  const handleNumberOfAdultChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
      return;
    }
    if (isNaN(value) || +value <= 0) {
      showErrorToast("Please enter a valid number of adults");
      setErrors((prev) => ({ ...prev, no_of_adults: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_adults: false }));
      }, 5000);

      return;
    }

    if (+value > availableRooms?.base_adult * bookingData.no_of_rooms) {
      showErrorToast(
        "The number of adults cannot be greater than the base occupancy of a room"
      );
      setErrors((prev) => ({ ...prev, no_of_adults: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_adults: false }));
      }, 5000);
      return;
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
    }
    setTimeout(() => {
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
    }, 5000);
  };

  //handle number of extra adult change
  const handleNumberOfExtraAdultChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, extra_adult: false }));
      return;
    }
    if (isNaN(value) || +value <= 0) {
      showErrorToast("Please enter a valid number of adults");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);
      return;
    }

    if (availableRooms.extra_bedding !== "enabled") {
      showErrorToast("Extra Bedding not allowed for this room");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);

      setBookingData({
        ...bookingData,
        [name]: 0,
      });
      return; // Return early if extra bedding is not allowed
    }
    if (bookingData.no_of_child !== "") {
      const backendMaxOccupancy =
        availableRooms?.max_adult * +bookingData.no_of_rooms;
      const frontEndMaxOccupancy =
        +bookingData.no_of_adults + +bookingData.no_of_child;
      const maxExtraAdultsAllowed =
        (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
        bookingData.no_of_rooms;
      if (+value > +maxExtraAdultsAllowed) {
        showErrorToast(
          "Max occupancy reached! please increase number of rooms"
        );
        setBookingData({
          ...bookingData,
          [name]: "", // Clear the input value
        });
        return;
      }
    }

    if (+value > availableRooms?.allowedExtraAdult * bookingData.no_of_rooms) {
      showErrorToast("Value cannot exceed the available extra adult per room");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, extra_adult: false }));
    }
  };

  //handle number of child change

  const handleNumberOfChildChange = (e) => {
    const { name, value } = e.target;
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_child: false }));
      return;
    }
    if (isNaN(value) || +value < 0) {
      showErrorToast("Please enter a valid number of children");
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_child: false }));
      }, 5000);
      setErrors((prev) => ({ ...prev, no_of_child: true }));
      return;
    }

    const childAllowed = availableRooms?.max_child;

    if (+value > childAllowed * +bookingData.no_of_rooms) {
      showErrorToast("Value cannot exceed the available child limit");
      setErrors((prev) => ({ ...prev, no_of_child: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_child: false }));
      }, 5000);
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
      return;
    }

    const backendMaxOccupancy =
      availableRooms?.max_adult * +bookingData.no_of_rooms;
    const frontEndMaxOccupancy =
      +bookingData.no_of_adults + +bookingData.extra_adults;
    const maxChildAllowed =
      (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
      bookingData.no_of_rooms;
    if (+value > +maxChildAllowed) {
      showErrorToast("Max occupancy reached! please increase number of rooms");
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
      return;
    }

    setBookingData({
      ...bookingData,
      [name]: value,
    });
    setErrors((prev) => ({ ...prev, no_of_child: false }));
  };

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };

  // salutations

  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);

  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  useEffect(() => {
    fetchSalutations();
  }, []);

  const handleSalutationChange = (e) => {
    const { name, value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestDetails({
        ...guestDetails,
        salutation: e.target.value,
      });
      setShowSalutationPopup(false);
    }
  };

  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handlePopupAddButtonClick = async (e) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            name: popUpSalutation,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPopUpSalutation(false);
        setPopUpSalutation("");
        // fetchSalutations();
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
    setGuestDetails({
      ...guestDetails,
      salutation: popUpSalutation,
    });
    setShowSalutationPopup(false);
  };
  const checkKeyValidity = (dataArray, keyName) => {
    for (const item of dataArray) {
      // Check if the key is empty or equal to 0
      if (!item[keyName] || item[keyName] === 0) {
        showErrorToast(`Invalid value for ${keyName.replace(/_/g, " ")}`);
        return true; // Stop further processing if an invalid value is found
      }
    }
  };

  const handleConfirmButtonClick = () => {
    if (isConfirmDisabled()) {
      showErrorToast("Please fill required Guest details.");
      return;
    }
    if (bookingDataArray.length === 0) {
      showErrorToast("Please add booking details");
      return;
    }

    if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
      return;
    }

    const roomInputData = bookingDataArray.map((item) => ({
      room_id: item.room_id || "",
      room_type_name: item.room_category || "",
      room_plan_id: item.room_rate_details.rate_plan_id || "",
      room_plan: item.room_rate_details?.rate_plan_type || "",
      no_of_rooms: item.no_of_rooms || "",
      no_nights: item.no_of_nights || "",
      room_rate: item.room_rate_new,
      no_adult: item.no_of_adults || 0,
      no_child: item.no_of_child || 0,
      extra_adult: item.extra_adults || 0,
      discount_rate: item?.discount_type?.discount_amount || 0,
      discount_amount: item?.discountAmount || 0,
      tax_rate: item.tax_rate || 0,
      tax_amount: item.taxAmount || 0,
      room_total_amount: item.subTotal || 0,
      booking_paxes: item.booking_paxes,
      room_grand_total: item.grandTotal,
    }));

    const addOns = addOnsPaymentData.addOn;

    const paymentData = addOnsPaymentData.payments;

    const postData = {
      status: "confirm",
      source: bookingData.booking_source,
     
      sales_agent: bookingData.agent_name,
      ota_source: bookingData.ota_source,
      hold_inventory_id: ID,
      booking_source_name: bookingData.booking_source,
      check_in: bookingData.check_in,
      check_out: bookingData.check_out,
      date: currentDate,
      agent_id: bookingData.agent_id,
      guest_name: guestDetails.salutation + " " + guestDetails.name,
      guest_email: guestDetails.email,
      guest_phone: guestDetails.phone,
      remarks: guestDetails.remarks,
      pincode: guestDetails.pincode || "",
      guest_city: guestDetails.city || "",
      guest_state: guestDetails.state || "",
      guest_address: guestDetails.address || "",
      gst_type: bookingData.tax_type || "",
      grand_total: sumGrandTotal,
      taxable_amount: sumSubTotal,
      total_tax_amount: sumTaxAmount,
      total_discount_amount: sumDiscountAmount,
      roomInputData: roomInputData,
      addOns: addOns,
      payments: paymentData,
    };

    

    // navigate("/bookings/createbooking/edit")

    // Check child age for each booking pax
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorToast("Please enter child's age.");
          return;
        }
      }
    }

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/bookings/store/new/bookings/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...postData,
            check_in: checkIn,
            check_out: checkOut,
            // property_current_date:
            //   propertyCurrentDate + " " + currentTimeToBackend,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            showErrorToast("Error while creating booking");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success === true) {
            // navigate("/bookings")
            showSuccessToast("Booking Created Successfully");

            navigate(`/bookings`);
          } else {
            showErrorToast("Error while creating booking");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function calculateDiscountAmountForBookingPaxes(bookingData) {
    let totalDiscountAmount = 0;
    // Loop through each booking in the array
    bookingData?.forEach((booking) => {
      // Loop through each booking pax in the booking
      booking?.booking_paxes?.forEach((pax) => {
        // Add the discount_amount of the booking pax to the totalDiscountAmount
        totalDiscountAmount += pax?.discount_amount;
      });
    });
    return totalDiscountAmount;
  }

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  // useEffect(() => {
  //   if (property_id) {
  //     getCurrentPropertyDateHandler(property_id?.id, userToken)
  //       .then((date) => {
  //         setPropertyCurrentDate(date);
  //         setHoldBookingData({
  //           hold_time: "",
  //           hold_date: date,
  //           hold_reason: "",
  //         });
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // }, [property_id]);

  useEffect(() => {
    if (
      bookingData.booking_source === "Walk In Booking" ||
      bookingData.booking_source === "Front Desk"
    ) {
      setBookingData({
        ...bookingData,
        booking_source_name: guestDetails.name,
      });
    }
    if (bookingData.booking_source === "Sales and Marketing") {
      setBookingData({
        ...bookingData,
        booking_source_name: bookingData.agent_name,
      });
    }
    if (bookingData.booking_source === "OTA") {
      setBookingData({
        ...bookingData,
        booking_source_name: bookingData.ota_source,
      });
    }
  }, [
    bookingData.booking_source,
    guestDetails.name,
    bookingData.agent_name,
    bookingData.ota_source,
  ]);

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  }
  const holdBookingDataChange = (e) => {
    setHoldBookingData({
      ...holdBookingData,
      [e.target.name]: e.target.value,
    });
  };

  const holdBookingTimeDataChange = (e) => {
    const { name, value } = e.target;
    const propertyDate = propertyCurrentDate;
    const selectedDate = holdBookingData.hold_date;

    if (holdBookingData.hold_date === "") {
      showErrorToast("Please select hold date");
      return;
    }

    if (propertyCurrentDate === selectedDate) {
      const currentTime = getCurrentTime();
      const selectedTime = value;

      if (selectedTime > currentTime) {
        setHoldBookingData({
          ...holdBookingData,
          [name]: value,
        });
        return;
      } else {
        setHoldBookingData({
          ...holdBookingData,
          [name]: "",
        });
        showErrorToast("hold time should be greater than current time");
        return;
      }
    } else if (selectedDate > propertyDate) {
      setHoldBookingData({
        ...holdBookingData,
        [name]: value,
      });
      return;
    } else {
      setHoldBookingData({
        ...holdBookingData,
        [name]: "",
      });
      return;
    }
  };

  // hold booking data submit
  const handleAssignButtonClick = () => {
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorToast("Please enter child's age.");
          return;
        }
      }
    }
    if (holdBookingData.hold_date === "") {
      showErrorToast("please enter the hold date");
      return;
    }
    if (holdBookingData.hold_time === "") {
      showErrorToast("please enter the hold time");
      return;
    }
    if (holdBookingData.hold_reason === "") {
      showErrorToast("please enter reason for holding the booking");
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
      return;
    }

    const roomInputData = bookingDataArray.map((item) => ({
      room_id: item.room_id || "",
      room_type_name: item.room_category || "",
      room_plan_id: item.room_rate_details.rate_plan_id || "",
      room_plan: item.room_rate_details?.rate_plan_type || "",
      no_of_rooms: item.no_of_rooms || "",
      no_nights: item.no_of_nights || "",
      room_rate: item.room_rate_new,
      no_adult: item.no_of_adults || 0,
      no_child: item.no_of_child || 0,
      extra_adult: item.extra_adults || 0,
      discount_rate: item?.discount_type?.discount_amount || 0,
      discount_amount: item?.discountAmount || 0,
      tax_rate: item.tax_rate || 0,
      tax_amount: item.taxAmount || 0,
      room_total_amount: item.subTotal || 0,
      booking_paxes: item.booking_paxes,
      room_grand_total: item.grandTotal,
    }));

    const addOns = addOnsPaymentData.addOn;

    const paymentData = addOnsPaymentData.payments;

    const postData = {
      status: "hold",
      hold_time: holdBookingData.hold_time,
      hold_date: holdBookingData.hold_date,
      hold_reason: holdBookingData.hold_reason,
      source: bookingData.booking_source,
      sales_agent: bookingData.agent_name,
      ota_source: bookingData.ota_source,

      booking_source_name: bookingData.booking_source_name,
      check_in: bookingData.check_in,
      check_out: bookingData.check_out,
      date: currentDate,
      agent_id: bookingData.agent_id,
      guest_name: guestDetails.salutation + " " + guestDetails.name,
      guest_email: guestDetails.email,
      guest_phone: guestDetails.phone,
      remarks: guestDetails.remarks,
      pincode: guestDetails.pincode || "",
      guest_city: guestDetails.city || "",
      guest_state: guestDetails.state || "",
      guest_address: guestDetails.address || "",
      gst_type: bookingData.tax_type || "",
      grand_total: sumGrandTotal,
      taxable_amount: sumSubTotal,
      total_tax_amount: sumTaxAmount,
      total_discount_amount: sumDiscountAmount,
      roomInputData: roomInputData,
      addOns: addOns,
      payments: paymentData,
    };

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/store/new/bookings/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...postData,
            check_in: checkIn,
            check_out: checkOut,
            property_current_date:
              propertyCurrentDate + " " + currentTimeToBackend,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            showErrorToast("Error while creating booking");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success === true) {
            // navigate("/bookings")
            showSuccessToast("Booking Created Successfully");

            navigate(`/bookings/createbooking/edit/${data.booking_id}`);
          } else {
            showErrorToast("Error while creating booking");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // array for tracking the show adhoc room rates form child to show the discount
  const [adhocArray, setAdhocArray] = useState([]);

  const getAdhocArrayFromChild = (data, index) => {
    setAdhocArray((prev) => {
      const newArray = [...prev];
      newArray[index] = data;
      return newArray;
    });
  };

  // discount type

  const handleDiscountTypeChange = (e) => {
    const id = e?.target?.value;

    const discount = discountOptions?.find((item) => item?.id == id);
    setBookingData({
      ...bookingData,
      discount_type: discount,
    });
  };

  useEffect(() => {
    if (adhocArray.includes(true)) {
      setBookingData({
        ...bookingData,
        discount_type: null,
      });
    }
  }, [adhocArray, setBookingData]);

  return (
    <div className="main_container">
      <div className="create_booking_page_main">
        <div
          className="create_booking_page_top flex align_center"
          style={{ padding: "5px 0" }}
        >
          <div className="flex align_center">
            <CreateBookingSelectBox
              label="Booking Type"
              value={bookingData.booking_type}
              onChange={handleBookingTypeChange}
              options={BookingTypeOptions}
              disabled={bookingDataArray.length > 0}
              error={errors.booking_type}
            />

            <div
              className="create_booking_selectBox_container"
              onClick={() => setErrors({ no_of_nights: false })}
            >
              <DateRangePickerNew
                dataFromChild={dataFromChild}
                minDate={new Date(bookingData?.hold_from_date)}
                maxDate={new Date(bookingData?.hold_to_date)}
                disabled={
                  bookingDataArray.length > 0 ||
                  bookingData.room_category !== ""
                }
                error={errors.no_of_nights}
              />
            </div>

            {/* room category */}

            <CreateBookingSelectBoxOptionDisabled
              // disabled={
              //   bookingData.check_in && bookingData.check_out ? false : true
              // }
              label="Room Category"
              value={bookingData.room_category}
              onChange={handleRoomCategory}
              options={RoomCategoryOptions}
              error={errors.room_category}
              bookingDataArray={bookingDataArray}
            />

            <CreateBookingInputBox
              alignment="vertical"
              label="No. of Nights"
              disabled={true}
              value={bookingData.no_of_nights}
              error={errors.no_of_nights}
            />
            <CreateBookingInputBox
              alignment="vertical"
              label="No. of Rooms"
              onChange={handleNumberOfRoomsChange}
              name="no_of_rooms"
              value={bookingData.no_of_rooms}
              error={errors.no_of_rooms}
              disabled={bookingData.room_category === ""}
            />
            <CreateBookingInputBox
              alignment="vertical"
              label="No. of Adults"
              onChange={handleNumberOfAdultChange}
              name="no_of_adults"
              value={bookingData.no_of_adults}
              error={errors.no_of_adults}
              disabled={bookingData.room_category === ""}
            />
            <CreateBookingInputBox
              alignment="vertical"
              label="Extra Adults"
              onChange={handleNumberOfExtraAdultChange}
              name="extra_adults"
              value={bookingData.extra_adults}
              error={errors.extra_adult}
              disabled={
                bookingData.room_category === "" ||
                bookingData.no_of_adults === ""
              }
            />
            <CreateBookingInputBox
              alignment="vertical"
              label="No. of Child"
              name="no_of_child"
              onChange={handleNumberOfChildChange}
              value={bookingData.no_of_child}
              error={errors.no_of_child}
              disabled={
                bookingData.room_category === "" ||
                bookingData.no_of_adults === ""
              }
            />
          </div>
          <div>
            {availableRooms.available_rooms !== undefined && (
              <p
                className="avail_room"
                style={{
                  fontSize: "12px",
                  backgroundColor:
                    availableRooms.available_rooms === 0
                      ? "rgb(255 0 0 / 18%)"
                      : "",
                  color: availableRooms.available_rooms === 0 ? "red" : "",
                }}
              >
                {availableRooms.available_rooms === 0
                  ? "Rooms not available"
                  : `Available Rooms: ${availableRooms.available_rooms}`}
              </p>
            )}

            {(availableRooms.base_adult !== undefined ||
              availableRooms.base_adult === "") && (
              <p style={{ fontSize: "10px" }}>
                Base Occupancy: {availableRooms.base_adult}
              </p>
            )}

            {(availableRooms.max_adult !== undefined ||
              availableRooms.max_adult === "") && (
              <p style={{ fontSize: "10px" }}>
                Max. Occupancy: {availableRooms.max_adult}
              </p>
            )}

            {(availableRooms.allowedExtraAdult !== undefined ||
              availableRooms.allowedExtraAdult === "") && (
              <p
                style={{
                  fontSize: "10px",
                  color: availableRooms.allowedExtraAdult ? "green" : "red",
                }}
              >
                Extra Adult Allowed:{" "}
                {availableRooms.allowedExtraAdult
                  ? `Enabled (${availableRooms.allowedExtraAdult})`
                  : "Disabled"}
              </p>
            )}

            {(availableRooms.max_child !== undefined ||
              availableRooms.max_child === "") && (
              <p style={{ fontSize: "10px" }}>
                Child Allowed: {availableRooms.max_child}
              </p>
            )}
            <></>
          </div>
        </div>
        <div className="create_booking_page_mid flex gap align_items_end">
          <CreateBookingSelectBox
            label="Booking Source"
            value={bookingData?.booking_source}
            onChange={handleBookingSourceChange}
            options={BookingSourceOptions}
            // disabled={bookingDataArray.length > 0}
            error={errors.booking_source}
            disabled={true}
          />

          {/* {bookingData.booking_source === "Agent (B2B) Partner" && (
            <label>
              Company Name <br />
              <select
                onChange={(e) => handleInputSelectAgentChange(e)}
                name="agent_id"
                className={
                  errors.agent_id
                    ? "createBookingInputAndSelectTags create_booking_error-border"
                    : "createBookingInputAndSelectTags"
                }
                style={{
                  border: errors.agent_id
                    ? "1px solid #E03838"
                    : "1px solid #ddd",
                  backgroundColor: errors.agent_id ? "#FFEAEA" : "",
                }}
                value={bookingData.agent_id}
                disabled={true}
              >
                <option>Select</option>
                {agentList.map((item, index) => {
                  return (
                    <option key={index} value={item.agent_information}>
                      {item.company_name}
                    </option>
                  );
                })}
                <option value={"new_agent"}>new agent</option>
              </select>
            </label>
          )} */}
          {bookingData.booking_source === "OTA" && (
            <label>
              Select OTA <br />
              <select
                onChange={handleInputOtaChange}
                name="ota_source"
                className={
                  errors.ota_source
                    ? "createBookingInputAndSelectTags create_booking_error-border"
                    : "createBookingInputAndSelectTags"
                }
                style={{
                  border: errors.ota_source
                    ? "1px solid #E03838"
                    : "1px solid #ddd",
                  backgroundColor: errors.ota_source ? "#FFEAEA" : "",
                }}
                value={bookingData.ota_source}
              >
                <option value={""}>Select</option>
                {otaTypeOptions.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </label>
          )}
          {bookingData.agent_id !== "" &&
            bookingData.agent_id &&
            bookingData.booking_source === "Agent (B2B) Partner" && (
              <div>
                <label>TAC Disc. %</label> <br />
                <input
                  type="number"
                  name="tac_discount"
                  disabled
                  value={bookingData?.tac_discount}
                  className="createBookingInputAndSelectTags"
                />
              </div>
            )}
          {bookingData.booking_source === "Sales and Marketing" && (
            <div>
              <label>Sales Agent Name</label> <br />
              <input
                type="name"
                name="agent_name"
                onChange={handleInputAgentNameChange}
                value={bookingData?.agent_name}
                className={
                  errors.agent_name
                    ? " createBookingInputAndSelectTags create_booking_error-border"
                    : "createBookingInputAndSelectTags"
                }
                style={{
                  border: errors.agent_name
                    ? "1px solid #E03838"
                    : "1px solid #ddd",
                  backgroundColor: errors.agent_name ? "#FFEAEA" : "",
                }}
              />
            </div>
          )}

          <div>
            <BgThemeButton
              onClick={handleClickOnAddButton}
              type={"submit"}
              children={"Add Room"}
              disabled={
                availableRooms !== "" && availableRooms.available_rooms === 0
                  ? true
                  : false
              }
              style={{
                background:
                  availableRooms !== "" && availableRooms.available_rooms === 0
                    ? "#6483db"
                    : "",
                cursor:
                  availableRooms !== "" && availableRooms.available_rooms === 0
                    ? "not-allowed"
                    : "",
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          margin: "10px 0",
          justifyContent: "space-between",
        }}
      >
        <div className="create_booking_page_left_booking_card_container">
          {bookingDataArray.length > 0 && (
            <>
              {bookingDataArray.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <AgentHoldRoomsCreateBookingCard
                      index={index}
                      singleBookingData={data}
                      setBookingDataArray={setBookingDataArray}
                      bookingDataArray={bookingDataArray}
                      handleDeleteBookingCard={handleDeleteBookingCard}
                      discount_type={bookingData.discount_type}
                      tax_type={bookingData.tax_type}
                      getAdhocArrayFromChild={getAdhocArrayFromChild}
                      adhocArray={adhocArray}
                      property_id={property_id}
  
                    />
                  </React.Fragment>
                );
              })}
            </>
          )}

          {/* guest details */}

          <div className="create_booking_primary_guest_details_container_wrapper">
            <div className="create_booking_primary_guest_details_container">
              <h4>Primary Guest Details</h4>
              <div className="create_booking_primary_guest_details">
                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">
                    Name <span style={{ color: "red" }}>*</span> <br />
                  </label>
                  <div className="flex gap_5">
                    <select
                      id="salutation"
                      name="salutation"
                      value={guestDetails.salutation}
                      onChange={handleSalutationChange}
                      className="create_booking_primary_guest_details_input"
                      style={{
                        width: "50px",
                        marginRight: "5px",
                        background: errors.guest_name ? "#FFEAEA" : "",
                        border: errors.guest_name ? "1px solid #E03838" : "",
                      }}
                    >
                      <option value="">Select...</option>
                      {salutations?.map((salutation, index) => (
                        <option key={index} value={salutation.name}>
                          {salutation.name}
                        </option>
                      ))}
                      <option value={"other"}>Other</option>
                    </select>

                    {showSalutationPopup && (
                      <input
                        type="text"
                        required
                        className="create_booking_primary_guest_details_input"
                        name="salutation"
                        value={guestDetails.salutation}
                        onChange={handleGuestDetails}
                        style={{ width: "100px", marginRight: "5px" }}
                      />
                    )}

                    <input
                      type="text"
                      required
                      className="create_booking_primary_guest_details_input"
                      name="name"
                      value={guestDetails.name}
                      onChange={handleGuestDetails}
                      style={{
                        background: errors.guest_name ? "#FFEAEA" : "",
                        border: errors.guest_name ? "1px solid #E03838" : "",
                      }}
                    />
                  </div>
                </div>
                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">Phone </label> <br />
                  <input
                    type="number"
                    required
                    className="create_booking_primary_guest_details_input"
                    value={guestDetails.phone}
                    name="phone"
                    onChange={handleGuestDetails}
                  />
                </div>

                <div className="create_booking_primary_guest_details_input_container">
                  <label className="standard-select">Email </label> <br />
                  <input
                    type="email"
                    className="create_booking_primary_guest_details_input"
                    value={guestDetails.email}
                    name="email"
                    onChange={handleGuestDetails}
                  />
                </div>
              </div>
            </div>
            <div className="create_booking_primary_guest_details_container">
              <h4>Remarks</h4>
              <div className="create_booking_primary_guest_details">
                <textarea
                  type="text"
                  required
                  className="create_booking_primary_guest_details_input_remarks"
                  name="remarks"
                  value={guestDetails.remarks}
                  onChange={handleGuestDetails}
                  style={{ width: "100%", resize: "none", padding: "5px" }}
                />
              </div>
            </div>
          </div>

          {/* <PrimaryGuestDetails
            guestDetails={guestDetails}
            setGuestDetails={setGuestDetails}
            errors={errors}
            setErrors={setErrors}
          /> */}
        </div>

        {/* total amount Card   */}

        <div className="create_booking_page_total_amount_card">
          {/* setting the discount type and tax type changing these will change the total amount */}
          {/* this select tag is sending id to handler and it sets discount type object in BookingData object */}
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              gap: "10px",
              flexWrap: "wrap",
              padding: "20px",
              borderBottom: "0.5px solid #333",
            }}
          >
            <div>
              {!adhocArray.includes(true) && (
                <>
                  <label>Disc Type</label> <br />
                  <select
                    onChange={handleDiscountTypeChange}
                    name="discount_id"
                    className="createBookingInputAndSelectTags"
                    style={{ marginTop: "5px", width: "150px" }}
                    value={bookingData?.discount_type?.id}
                  >
                    <option value="">Select</option>

                    {discountOptions && discountOptions.length > 0 ? (
                      discountOptions.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.discount_name} {item.discount_amount}
                        </option>
                      ))
                    ) : (
                      <option disabled>No discount added</option>
                    )}
                  </select>
                </>
              )}
            </div>
            <div>
              <label className="Create_booking_label">Tax</label>
              <div className="flex gap_7">
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value="inclusive"
                    checked={bookingData.tax_type === "inclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Inclusive
                </label>
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value={"exclusive"}
                    checked={bookingData.tax_type === "exclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Exclusive
                </label>
              </div>
            </div>
          </div>
          <div style={{ padding: "20px", position: "relative" }}>
            <div className="crate_booking_page_total_amount_heading">
              <span>Subtotal</span>
              <span>
                {formatIndianCurrency(+sumSubTotal + +sumSubtotalAddons)}{" "}
              </span>
            </div>
            <div className="crate_booking_page_total_amount_heading">
              <span>Discount Amt.</span>
              <span>{formatIndianCurrency(+sumDiscountAmount)}</span>
            </div>

            <div className="crate_booking_page_total_amount_heading">
              <span>Tax Amt.</span>
              <span>
                {formatIndianCurrency(+sumTaxAmount + +sumTaxAmountAddons)}{" "}
              </span>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#3968ED" }}
            >
              <span>Grand Total</span>
              <span>
                {formatIndianCurrency(+sumGrandTotal + +sumGrandTotalAddons)}
              </span>
            </div>

            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#15AA12" }}
            >
              <h4>Paid Amt.</h4>
              <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#BD941B" }}
            >
              <h4>Due Amt.</h4>
              <h3>
                {formatIndianCurrency(
                  +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
                )}
              </h3>
            </div>
          </div>

          <div className="flex gap_7" style={{ padding: "20px" }}>
            <BgThemeButton
              onClick={handleConfirmButtonClick}
              type={"submit"}
              children={"Confirm"}
            />

            {/* <BgThemeButton
              style={{
                background: "#BD941B",
                cursor: bookingDataArray.length < 1 ? "not-allowed" : "",
              }}
              onClick={() => setShowHoldPopup(true)}
              disabled={bookingDataArray.length < 1}
            >
              Hold
            </BgThemeButton> */}
          </div>

          {showHoldPopup && (
            <>
              <div
                className="createBookingTableNewPopupContainer"
                onClick={() => setShowHoldPopup(false)}
              >
                <div
                  className="createBookingTableNewPopupContent"
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: "400px" }}
                >
                  <h3 style={{ textAlign: "center" }}>Hold Date & Time</h3>

                  <div className="d_flex gap_7" style={{ margin: "15px 0" }}>
                    <div style={{ width: "48%" }}>
                      <label>Select Date</label> <br />
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          className="addEvent-input"
                          min={propertyCurrentDate}
                          name="hold_date"
                          style={{ cursor: "pointer" }}
                          value={holdBookingData.hold_date}
                          onChange={holdBookingDataChange}
                          placeholder="Enter Date"
                        ></input>
                        <input
                          type="text"
                          className="addEvent-input"
                          name="hold_date"
                          value={formatDate(holdBookingData.hold_date) || ""}
                          onChange={holdBookingDataChange}
                          placeholder="dd/mm/yyyy"
                          style={{
                            position: "absolute",
                            width: "50%",
                            top: "2px",
                            left: "2px",
                            border: "none",
                            outline: "none",
                          }}
                        ></input>
                      </div>
                    </div>
                    <div style={{ width: "48%" }}>
                      <label>Select Time</label> <br />
                      {/* <select
                        className="addEvent-input"
                        name="hold_time"
                        value={holdBookingData.hold_time}
                        onChange={holdBookingDataChange}
                      >
                        {timeArray.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        type="time"
                        className="addEvent-input"
                        name="hold_time"
                        value={holdBookingData.hold_time}
                        onChange={holdBookingTimeDataChange}
                        min={getCurrentTime()}
                      />
                    </div>
                  </div>

                  <div>
                    <label>Reason</label> <br />
                    <textarea
                      className="addEvent-input"
                      name="hold_reason"
                      onChange={holdBookingDataChange}
                      style={{ width: "100%", resize: "none", padding: "5px" }}
                    />
                  </div>

                  <div className="createBookingTableNewPopupFooter">
                    <BgThemeButton
                      // type={"submit"}
                      children={"Assign"}
                      style={{ background: "#BD941B", width: "48%" }}
                      onClick={handleAssignButtonClick}
                    />
                    <BgOutlineThemeButton
                      //  type={"submit"}
                      children={"Discard"}
                      onClick={() => setShowHoldPopup(false)}
                      style={{ width: "48%" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default AgentHoldRoomsCreateBooking;

