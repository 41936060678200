import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { BgThemeButton, BgThemeButtonDisabled } from "../UI/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { checkIsAuthenticatedFalse } from "../../reduxToolkit/Slice/isAuthenticatedSlice";
import { Icon, Tooltip } from "@shopify/polaris";
import CreateKotNavbarButton from "./CreateKotNavbarButton";
import WhatsappBot from "../CustomComponents/CustomChatbot/WhatsappBot";
import AdditionalLinks from "./AdditionalLinks";
import User from "./User";
import { saveActiveProperty } from "../../reduxToolkit/Slice/propertyslice";
import SelectPropertyPopup from "../selectPropertryPopup/SelectPropertyPopup";

const Navbar = () => {
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const agentDetails = JSON.parse(localStorage.getItem("agentDetails"));

  const role = JSON.parse(localStorage.getItem("role"));

  const selectedProperty =localStorage.getItem("selectedProperty")   ? JSON.parse(localStorage.getItem("selectedProperty")) : null;

  const authenticated = useSelector(
    (state) => state.isAuthenticated.authenticated
  );
  const dispatch = useDispatch();
  const logoutHandle = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.reload();
  };

  const [hasPermission, setHasPermission] = useState(false);

  const checkPermission = (permissionToCheck) => {
    if (permissions.includes(permissionToCheck)) {
      setHasPermission(true);
    } else {
      setHasPermission(false);
    }
  };



  const [switchProperty, setSwitchProperty] = useState(false);
  



 

  return (
    <header className="navbar-container" >
      <div className="nav-header">
        <div className="nav-logo flex flex_gap_20">
          <Link to={"/"}>
            <img className="nav-logo--image" src={logo} alt="logo" />
          </Link>
          <WhatsappBot />
        </div>
        
        <AdditionalLinks />

        <div className="flex flex_gap_10 align_items_center">
          <div
            onClick={() => window.location.reload()}
            style={{
              cursor: "pointer",
              paddingTop: "5px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 29"
              fill="none"
            >
              <mask
                id="mask0_7124_1862"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="28"
                height="29"
              >
                <rect y="0.5" width="28" height="28" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_7124_1862)">
                <path
                  d="M5.95002 19.225C5.52224 18.4861 5.20141 17.7278 4.98752 16.95C4.77363 16.1722 4.66669 15.375 4.66669 14.5584C4.66669 11.9528 5.57085 9.73613 7.37919 7.90835C9.18752 6.08058 11.3945 5.16669 14 5.16669H14.2042L12.3375 3.30002L13.9709 1.66669L18.6375 6.33335L13.9709 11L12.3375 9.36669L14.2042 7.50002H14C12.0556 7.50002 10.4028 8.18544 9.04169 9.55627C7.68058 10.9271 7.00002 12.5945 7.00002 14.5584C7.00002 15.0639 7.05835 15.5597 7.17502 16.0459C7.29169 16.532 7.46669 17.0084 7.70002 17.475L5.95002 19.225ZM14.0292 27.3334L9.36252 22.6667L14.0292 18L15.6625 19.6334L13.7959 21.5H14C15.9445 21.5 17.5972 20.8146 18.9584 19.4438C20.3195 18.0729 21 16.4056 21 14.4417C21 13.9361 20.9417 13.4403 20.825 12.9542C20.7084 12.4681 20.5334 11.9917 20.3 11.525L22.05 9.77502C22.4778 10.5139 22.7986 11.2722 23.0125 12.05C23.2264 12.8278 23.3334 13.625 23.3334 14.4417C23.3334 17.0472 22.4292 19.2639 20.6209 21.0917C18.8125 22.9195 16.6056 23.8334 14 23.8334H13.7959L15.6625 25.7L14.0292 27.3334Z"
                  fill="#3968ED"
                />
              </g>
            </svg>
          </div>
          <div>
            <div
              style={{
                border: ".5px solid #ccc",
                padding: "5px",
                borderRadius: "4px",
                width: "max-content",
                fontSize: "11px",
              }}
            >
              Your TAC:{" "}
              {agentDetails &&
              agentDetails?.details?.category_details?.agent_cat_discount
                ? Math.trunc(
                    agentDetails?.details?.category_details?.agent_cat_discount
                  )
                : 0}
              %
            </div>
          </div>

          <User agentDetails={agentDetails} switchProperty={switchProperty} setSwitchProperty={setSwitchProperty} />
        </div>
      </div>
      {
      switchProperty && (
       <div position="fixed" top="0" left="0" width="100vw" height="100vh">
         <SelectPropertyPopup setSwitchProperty={setSwitchProperty}/>
       </div>
      )
    }
    </header>
  );
};

export default Navbar;
